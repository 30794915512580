import { useEffect, useState } from "react";

// Services
import { fetchVariants } from "services/catalog";

// Context

/**
 * Hook that fetches the available variants in the back-end,
 * and stores them in the state `variants`.
 *
 * It also exposes the following methods:
 *  - getTechnology: Given a variant id, it returns the variant info
 *    (filering the state, not calling BE).
 *
 * @returns {Array}
 */
function useVariants() {
  const [availableVariants, setAvailableVariants] = useState();
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  // TODO: Add error control.

  useEffect(() => {
    if (!availableVariants && !error && !isFetching) {
      setIsFetching(true);
      setError(null);
      fetchVariants()
        .then(variants => {
          setAvailableVariants(variants);
          setIsFetching(false);
        })
        .catch(err => {
          console.log(err);
          setError(typeof err === "object" ? err.message : err);
          setIsFetching(false);
        });
    }
  }, [error, isFetching, availableVariants]);

  const getVariant = id => {
    if (availableVariants) {
      const technology = availableVariants.find(item => item.id === id);
      return technology;
    }
  };
  return [availableVariants, { getVariant }];
}

export default useVariants;
