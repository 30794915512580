import React, { useMemo } from "react";
import PropTypes from "prop-types";

import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

/**
 * @typedef {"always-enabled" | "toggleable"} SectionType
 */

/**
 * CookieSettingsDialogSection component renders a section in the cookie settings dialog.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.title - The title of the cookie setting section.
 * @param {string} props.description - The description of the cookie setting section.
 * @param {SectionType} props.type - The type of the cookie setting.
 * @param {boolean} [props.isEnabled] - Optional, indicates if the cookie setting is enabled. If the type is "always-enabled", this value will be ignored.
 * @param {function} props.onToggle - Optional, the function to call when toggling the cookie setting. If the type is "always-enabled", this function will not be called.
 * @returns {React.ReactElement} The rendered CookieSettingsDialogSection component.
 */
export function CookieSettingsDialogSection({
  title,
  description,
  type,
  isEnabled,
  onToggle
}) {
  const theme = useTheme();

  const sectionOption = useMemo(() => {
    if (type === "always-enabled") {
      return (
        <Typography
          variant="body1"
          style={{
            color: theme.palette.success.main,
            fontWeight: "bold",
            fontSize: "0.9rem"
          }}
        >
          Always active
        </Typography>
      );
    }

    return (
      <Switch
        color="primary"
        size="small"
        checked={isEnabled}
        onChange={event => {
          if (onToggle) {
            onToggle(event.target.checked);
          }
        }}
      />
    );
  }, [isEnabled, onToggle, theme.palette.success.main, type]);

  return (
    <div
      style={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2)
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: theme.spacing(1)
        }}
      >
        <Typography variant="h6">{title}</Typography>
        {sectionOption}
      </div>
      <Typography variant="body1">{description}</Typography>
    </div>
  );
}

CookieSettingsDialogSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["always-enabled", "toggleable"]).isRequired,
  isEnabled: PropTypes.bool,
  onToggle: PropTypes.func
};
