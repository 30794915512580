// Third-party
import i18next from "i18next";

// Cesium
import {
  defined,
  when,
  Rectangle,
  DeveloperError,
  TileProviderError,
  RuntimeError,
  ImageryProvider,
  GeographicTilingScheme,
  Event,
  defaultValue,
  Credit,
  Resource
} from "cesium/Build/Cesium/Cesium";

const loadImage = ImageryProvider.loadImage;

/**
 * Provides a single, top-level imagery tile.  The single image is assumed to use a
 * {@link GeographicTilingScheme}.
 *
 * @alias GilyticsImageryProvider
 * @constructor
 *
 * @param {Object} options Object with the following properties:
 * @param {String|Canvas|Image} options.image A Property specifying the Image, URI, or Canvas to use for the tile.
 * @param {Rectangle} [options.rectangle=Rectangle.MAX_VALUE] The rectangle, in radians, covered by the image.
 * @param {Credit|String} [options.credit] A credit for the data source, which is displayed on the canvas.
 * @param {Ellipsoid} [options.ellipsoid] The ellipsoid.  If not specified, the WGS84 ellipsoid is used.
 * @param {Object} [options.proxy] A proxy to use for requests. This object is expected to have a getURL function which returns the proxied URL, if needed.
 *
 * @see ArcGisMapServerImageryProvider
 * @see BingMapsImageryProvider
 * @see GoogleEarthEnterpriseMapsProvider
 * @see OpenStreetMapImageryProvider
 * @see SingleTileImageryProvider
 * @see WebMapServiceImageryProvider
 * @see WebMapTileServiceImageryProvider
 * @see UrlTemplateImageryProvider
 */
function GilyticsImageryProvider(options) {
  options = defaultValue(options, {});
  let url = options.url;
  let image = options.image;

  this._url = url;
  if (defined(url)) {
    image = defaultValue(image, url);
  }

  if (!defined(image)) {
    throw new DeveloperError("options.image is required.");
  }

  this._proxy = options.proxy;

  this._resource = Resource.createIfNeeded(options.url);

  let rectangle = defaultValue(options.rectangle, Rectangle.MAX_VALUE);
  let tilingScheme = new GeographicTilingScheme({
    rectangle: rectangle,
    numberOfLevelZeroTilesX: 1,
    numberOfLevelZeroTilesY: 1,
    ellipsoid: options.ellipsoid
  });
  this._tilingScheme = tilingScheme;

  this._canvas = document.createElement("canvas");
  this._ctx = this._canvas.getContext("2d");

  this._image = undefined;
  this._texture = undefined;
  this._red = options.red;
  this._green = options.green;
  this._blue = options.blue;
  this._alpha = options.alpha;
  this._tileWidth = 0;
  this._tileHeight = 0;

  this._errorEvent = new Event();

  this._ready = false;
  this._readyPromise = when.defer();

  let credit = options.credit;
  if (typeof credit === "string") {
    credit = new Credit(credit);
  }
  this._credit = credit;

  var that = this;
  var error;

  const success = () => {
    this._ready = true;
    this._readyPromise.resolve(true);
    TileProviderError.handleSuccess(this._errorEvent);
  };

  const failure = e => {
    let location = "";
    if (typeof image === "string") {
      location = " " + image;
    }
    let message =
      i18next.t("GilyticsImageryProvider.imageFailed") + location + ".";
    error = TileProviderError.handleError(
      error,
      this,
      this._errorEvent,
      message,
      0,
      0,
      0,
      doRequest,
      e
    );
    this._readyPromise.reject(new RuntimeError(message));
  };

  const doRequest = () => {
    getImage(that, image).then(success).otherwise(failure);
  };

  doRequest();
}
/**
 * Create canvas
 * @param {*} that
 * @param {*} imageObj
 */
const successLoad = (that, imageObj) => {
  that._originalImage = imageObj;
  that._canvas.width = imageObj.width;
  that._canvas.height = imageObj.height;

  that._ctx.clearRect(0, 0, that._canvas.width, that._canvas.height);

  // flipY
  that._ctx.translate(0, imageObj.height);
  that._ctx.scale(1, -1);
  // Draw Image
  that._ctx.drawImage(imageObj, 0, 0);

  let imageData = that._ctx.getImageData(
    0,
    0,
    that._canvas.width,
    that._canvas.height
  );
  let imageColored = recolorImage(that, imageData);
  that._ctx.putImageData(imageData, 0, 0);

  that._image = imageColored;
  that._tileWidth = imageColored.width;
  that._tileHeight = imageColored.height;
};

/**
 * Get current Image
 * @param {*} that
 * @param {*} image
 * @returns
 */
function getImage(that, image) {
  // We have a Image, Canvas
  if (typeof image !== "string") {
    successLoad(that, image);
    return when(image);
  }

  let proxy = that._proxy;
  if (defined(proxy)) {
    image = proxy.getURL(image);
  }

  return loadImage(null, that._resource).then(imageObj => {
    successLoad(that, imageObj);
    return imageObj;
  });
}

/**
 * Recolor a raster image pixel by pixel, replacing encoded identifiers with some calculated value.
 * @param {*} image
 * @param {*} colorFunc
 * @returns
 */
const recolorImage = (that, image) => {
  let length = image.data.length; //pixel count * 4
  for (let i = 0; i < length; i += 4) {
    // Convert that identifier into the data-mapped colour it should display as.
    let clr = colorFunction(
      that,
      image.data[i],
      image.data[i + 1],
      image.data[i + 2]
    );
    if (defined(clr)) {
      for (let j = 0; j < 4; j++) {
        image.data[i + j] = clr[j];
      }
    }
  }
  return image;
};

/**
 * Get new RGB values
 * @param {*} that
 * @param {*} pr
 * @param {*} pg
 * @param {*} pb
 * @returns
 */
function colorFunction(that, pr, pg, pb) {
  let nr = pr / 255;
  let ng = pg / 255;
  let nb = pb / 255;
  let m = (nr + ng + nb) / 3;

  return [
    255 * (1 - m) + that._red * m,
    255 * (1 - m) + that._green * m,
    255 * (1 - m) + that._blue * m,
    255 * that._alpha
  ];
}

Object.defineProperties(GilyticsImageryProvider.prototype, {
  /**
   * Gets the URL of the single, top-level imagery tile.
   * @memberof GilyticsImageryProvider.prototype
   * @type {String}
   * @readonly
   * @deprecated
   */
  url: {
    get: function () {
      return this._url;
    }
  },

  /**
   * Gets or sets the image of this tile
   * @memberof GilyticsImageryProvider.prototype
   * @type {String|Canvas|Image}
   * @readonly
   */
  image: {
    get: function () {
      return this._originalImage;
    }
  },
  /**
   * Gets or sets the color of this tile
   * @memberof GilyticsImageryProvider.prototype
   * @type {Array}
   * @readonly
   */
  color: {
    get: function () {
      return [this._red, this._green, this._blue, this._alpha];
    },
    set: function (value) {
      if (
        this._red !== value[0] ||
        this._green !== value[1] ||
        this._blue !== value[2] ||
        this._alpha != value[3]
      ) {
        this._red = value[0];
        this._green = value[1];
        this._blue = value[2];
        this._alpha = value[3];
        this.reload();
      }
    }
  },

  /**
   * Gets the proxy used by this provider.
   * @memberof GilyticsImageryProvider.prototype
   * @type {Proxy}
   * @readonly
   */
  proxy: {
    get: function () {
      return this._proxy;
    }
  },

  /**
   * Gets the width of each tile, in pixels. This function should
   * not be called before {@link GilyticsImageryProvider#ready} returns true.
   * @memberof GilyticsImageryProvider.prototype
   * @type {Number}
   * @readonly
   */
  tileWidth: {
    get: function () {
      if (!this._ready) {
        throw new DeveloperError(
          "tileWidth must not be called before the imagery provider is ready."
        );
      }
      return this._tileWidth;
    }
  },

  /**
   * Gets the height of each tile, in pixels.  This function should
   * not be called before {@link GilyticsImageryProvider#ready} returns true.
   * @memberof GilyticsImageryProvider.prototype
   * @type {Number}
   * @readonly
   */
  tileHeight: {
    get: function () {
      if (!this._ready) {
        throw new DeveloperError(
          "tileHeight must not be called before the imagery provider is ready."
        );
      }
      return this._tileHeight;
    }
  },

  /**
   * Gets the maximum level-of-detail that can be requested.  This function should
   * not be called before {@link GilyticsImageryProvider#ready} returns true.
   * @memberof GilyticsImageryProvider.prototype
   * @type {Number}
   * @readonly
   */
  maximumLevel: {
    get: function () {
      if (!this._ready) {
        throw new DeveloperError(
          "maximumLevel must not be called before the imagery provider is ready."
        );
      }
      return 0;
    }
  },

  /**
   * Gets the minimum level-of-detail that can be requested.  This function should
   * not be called before {@link GilyticsImageryProvider#ready} returns true.
   * @memberof GilyticsImageryProvider.prototype
   * @type {Number}
   * @readonly
   */
  minimumLevel: {
    get: function () {
      if (!this._ready) {
        throw new DeveloperError(
          "minimumLevel must not be called before the imagery provider is ready."
        );
      }
      return 0;
    }
  },

  /**
   * Gets the tiling scheme used by this provider.  This function should
   * not be called before {@link GilyticsImageryProvider#ready} returns true.
   * @memberof GilyticsImageryProvider.prototype
   * @type {TilingScheme}
   * @readonly
   */
  tilingScheme: {
    get: function () {
      if (!this._ready) {
        throw new DeveloperError(
          "tilingScheme must not be called before the imagery provider is ready."
        );
      }
      return this._tilingScheme;
    }
  },

  /**
   * Gets the rectangle, in radians, of the imagery provided by this instance.  This function should
   * not be called before {@link GilyticsImageryProvider#ready} returns true.
   * @memberof GilyticsImageryProvider.prototype
   * @type {Rectangle}
   * @readonly
   */
  rectangle: {
    get: function () {
      return this._tilingScheme.rectangle;
    }
  },

  /**
   * Gets the tile discard policy.  If not undefined, the discard policy is responsible
   * for filtering out "missing" tiles via its shouldDiscardImage function.  If this function
   * returns undefined, no tiles are filtered.  This function should
   * not be called before {@link GilyticsImageryProvider#ready} returns true.
   * @memberof GilyticsImageryProvider.prototype
   * @type {TileDiscardPolicy}
   * @readonly
   */
  tileDiscardPolicy: {
    get: function () {
      if (!this._ready) {
        throw new DeveloperError(
          "tileDiscardPolicy must not be called before the imagery provider is ready."
        );
      }
      return undefined;
    }
  },

  /**
   * Gets an event that is raised when the imagery provider encounters an asynchronous error.  By subscribing
   * to the event, you will be notified of the error and can potentially recover from it.  Event listeners
   * are passed an instance of {@link TileProviderError}.
   * @memberof GilyticsImageryProvider.prototype
   * @type {Event}
   * @readonly
   */
  errorEvent: {
    get: function () {
      return this._errorEvent;
    }
  },

  /**
   * Gets a value indicating whether or not the provider is ready for use.
   * @memberof GilyticsImageryProvider.prototype
   * @type {Boolean}
   * @readonly
   */
  ready: {
    get: function () {
      return this._ready;
    }
  },

  /**
   * Gets a promise that resolves to true when the provider is ready for use.
   * @memberof GilyticsImageryProvider.prototype
   * @type {Promise.<Boolean>}
   * @readonly
   */
  readyPromise: {
    get: function () {
      return this._readyPromise.promise;
    }
  },

  /**
   * Gets the credit to display when this imagery provider is active.  Typically this is used to credit
   * the source of the imagery.  This function should not be called before {@link GilyticsImageryProvider#ready} returns true.
   * @memberof GilyticsImageryProvider.prototype
   * @type {Credit}
   * @readonly
   */
  credit: {
    get: function () {
      return this._credit;
    }
  },

  /**
   * Gets a value indicating whether or not the images provided by this imagery provider
   * include an alpha channel.  If this property is false, an alpha channel, if present, will
   * be ignored.  If this property is true, any images without an alpha channel will be treated
   * as if their alpha is 1.0 everywhere.  When this property is false, memory usage
   * and texture upload time are reduced.
   * @memberof GilyticsImageryProvider.prototype
   * @type {Boolean}
   * @readonly
   */
  hasAlphaChannel: {
    get: function () {
      return true;
    }
  }
});

/**
 * Reloads the image.
 */
GilyticsImageryProvider.prototype.reload = function () {
  if (defined(this._reload)) {
    getImage(this, this._originalImage).then(this._reload);
  }
};

/**
 * Gets the credits to be displayed when a given tile is displayed.
 *
 * @param {Number} x The tile X coordinate.
 * @param {Number} y The tile Y coordinate.
 * @param {Number} level The tile level;
 * @returns {Credit[]} The credits to be displayed when the tile is displayed.500 m
Color actualizado
￼
  
{% if 'pathfinder.gilytics.com' in request.get_host %} {# Only for prod #} %} {% endif %} {% if 'pathfinder.gilytics.com' in request.get_host %} {# Only for prod #} %} {% endif %} 
 *
 * @exception {DeveloperError} <code>getTileCredits</code> must not be called before the imagery provider is ready.
 */
GilyticsImageryProvider.prototype.getTileCredits = function (x, y, level) {
  return undefined;
};

/**
 * Requests the image for a given tile.  This function should
 * not be called before {@link GilyticsImageryProvider#ready} returns true.
 *
 * @param {Number} x The tile X coordinate.
 * @param {Number} y The tile Y coordinate.
 * @param {Number} level The tile level.
 * @param {Request} [request] The request object. Intended for internal use only.
 * @returns {Promise.<Image|Canvas>|undefined} A promise for the image that will resolve when the image is available, or
 *          undefined if there are too many active requests to the server, and the request
 *          should be retried later.  The resolved image may be either an
 *          Image or a Canvas DOM object.
 *
 * @exception {DeveloperError} <code>requestImage</code> must not be called before the imagery provider is ready.
 */
GilyticsImageryProvider.prototype.requestImage = function (
  x,
  y,
  level,
  request
) {
  if (!this._ready) {
    throw new DeveloperError(
      "requestImage must not be called before the imagery provider is ready."
    );
  }
  return this._image;
};

/**
 * Picking features is not currently supported by this imagery provider, so this function simply returns
 * undefined.
 *
 * @param {Number} x The tile X coordinate.
 * @param {Number} y The tile Y coordinate.
 * @param {Number} level The tile level.
 * @param {Number} longitude The longitude at which to pick features.
 * @param {Number} latitude  The latitude at which to pick features.
 * @return {Promise.<ImageryLayerFeatureInfo[]>|undefined} A promise for the picked features that will resolve when the asynchronous
 *                   picking completes.  The resolved value is an array of {@link ImageryLayerFeatureInfo}
 *                   instances.  The array may be empty if no features are found at the given location.
 *                   It may also be undefined if picking is not supported.
 */
GilyticsImageryProvider.prototype.pickFeatures = function (
  x,
  y,
  level,
  longitude,
  latitude
) {
  return undefined;
};

export default GilyticsImageryProvider;
