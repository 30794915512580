// Third-party
import * as Sentry from "@sentry/browser";

// Cesium
import {
  SingleTileImageryProvider,
  Rectangle
} from "cesium/Build/Cesium/Cesium";

// Services
import { getRasterLayerRanges } from "services/layer";
import { getTileProps } from "services/tilemapresource";

// Utils
import GilyticsTileMapServiceImageryProvider from "utils/Cesium/GilyticsTileMapServiceImageryProvider";

// Constants
import { corridorLayerName, resistanceLayerName } from "assets/global";

/**
 * Add Imagery Layer to Cesium.
 *
 * @param {Cesium.viewer} viewer Cesium viewer.
 * @param {string} name Name of the layer.
 * @param {object} data Data of the layer.
 * @param {number} opacity Opacity of the layer.
 *
 */
export const addImageryLayer = (viewer, name, data, opacity = 1) => {
  let layer = new SingleTileImageryProvider({
    url: data.png_data,
    rectangle: Rectangle.fromDegrees(...data.png_bounds)
  });

  let imgLayer = viewer.imageryLayers.addImageryProvider(
    layer,
    viewer.imageryLayers.length
  );

  imgLayer.alpha = opacity;
  imgLayer.name = name;
};

/**
 * Remove ImageryLayer by name.
 *
 * @param {Cesium.viewer} viewer Cesium viewer.
 * @param {string} name Name of the layer.
 */
export function removeImageryLayerByName(viewer, name) {
  let iLayers = viewer.imageryLayers;
  let i;
  for (i = 0; i < iLayers.length; i++) {
    if (iLayers._layers[i].name.indexOf(name) >= 0) {
      iLayers.remove(iLayers._layers[i]);
      i--;
    }
  }
  viewer.scene.requestRender();
}

/**
 * Change Imagery layers opacity.
 *
 * @param {Cesium.viewer} viewer Cesium viewer.
 * @param {string} name Name of the layer.
 * @param {number} opacity Opacity of the layer.
 */
export function changeImageryLayerOpacityByName(viewer, name, opacity) {
  let i;
  let l = viewer.imageryLayers.length;
  for (i = 0; i < l; i++) {
    if (viewer.imageryLayers._layers[i].name === name) {
      viewer.imageryLayers._layers[i].alpha = Number(opacity);
    }
  }
  viewer.scene.requestRender();
}

/**
 * Method to add the TMS imagery to the globe.
 *
 * @param {object} viewer Cesium viewer
 * @param {object} gradient Color gradient for the layer
 * @param {string} name Name of the layer
 * @param {array} extent Extent of the layer
 * @param {string} color Color for the layer
 * @param {string} tmsType Type of TMS
 * @param {object} res Results of fetching getTileProps
 */
export function addImageryTMS(
  viewer,
  gradient,
  name,
  extent,
  color,
  tmsType,
  res,
  alpha = 0.7
) {
  let datetime = "";
  datetime = "?" + new Date().getTime();

  // Tiles Url
  let tms = new GilyticsTileMapServiceImageryProvider({
    viewer: viewer,
    url: res.url + datetime,
    fileExtension: res.extension,
    rectangle: Rectangle.fromDegrees(...extent),
    minimumLevel: res.minZoom,
    maximumLevel: res.maxZoom,
    tileWidth: res.width,
    tileHeight: res.height,
    gradient: gradient,
    color: color,
    rasterType: tmsType
  });

  // Remove layer if exist before add it
  removeImageryLayerByName(viewer, name);

  let layers = viewer.scene.globe.imageryLayers;
  let imgLayer;
  /**
   * We can't add imageryslayerscollections inside others colletions,
   * so we have to make a hilarous method to manage the visibility of
   * the cesium imagery layers.
   */
  let isCorridor = name.includes(corridorLayerName);
  let isResistance = name.includes(resistanceLayerName);

  let resistanceIndex = 0;
  let corridorIndex = 0;

  layers._layers.forEach(layer => {
    if (layer.name.includes(resistanceLayerName)) {
      resistanceIndex++;
    }
    if (layer.name.includes(corridorLayerName)) {
      corridorIndex++;
    }
  });

  // Check if i corridor the current layer
  if (isCorridor) {
    let newCorridorIndex = resistanceIndex + corridorIndex + 1;
    imgLayer = layers.addImageryProvider(tms, newCorridorIndex);
  }
  // Check if resistance the current layer
  else if (isResistance) {
    let newResistanceIndex = 1 + resistanceIndex;
    imgLayer = layers.addImageryProvider(tms, newResistanceIndex);
  } else {
    // Insert in the top by default
    imgLayer = layers.addImageryProvider(tms);
  }
  // Set layer name
  imgLayer.name = name;
  imgLayer.alpha = alpha;
}

/**
 * Load TileMapService. We use the source parameter in the payload to
 * identify if the layer is a corridor or a resistance map. In that case
 * we won't call back-end for the ranges to set the color.
 *
 * @param {object} payload
 */
export function loadTMSWithColor(
  payload = {
    layerId: null,
    viewer: null,
    gradient: null,
    tmsUrl: null,
    name: "",
    extent: null,
    color: null,
    source: null,
    alpha: null
  }
) {
  let tmsType = "dem";
  getTileProps(payload.tmsUrl).then(res => {
    if (payload.source !== "corridor" && payload.source !== "resistanceMap") {
      getRasterLayerRanges(payload.layerId)
        .then(range => {
          if (range.max - range.min <= 1) {
            tmsType = "oneColor";
          }
        })
        .catch(err => Sentry.captureException(err))
        .finally(() => {
          addImageryTMS(
            payload.viewer,
            payload.gradient,
            payload.name,
            payload.extent,
            payload.color,
            tmsType,
            res,
            payload.alpha
          );
        });
    } else {
      addImageryTMS(
        payload.viewer,
        payload.gradient,
        payload.name,
        payload.extent,
        payload.color,
        tmsType,
        res,
        payload.alpha
      );
    }
  });
}

/**
 * Raise to top imageryLayer by name (Z Index).
 *
 * @param {Cesium.viewer} viewer Cesium viewer.
 * @param {string} name The name of the imagery layer.
 */
export function raiseToTopImageryLayerByName(viewer, name) {
  let iLayers = viewer.imageryLayers;
  let i;
  let l = iLayers.length;
  for (i = 0; i < l; i++) {
    if (iLayers._layers[i].name.indexOf(name) >= 0) {
      iLayers.raiseToTop(iLayers._layers[i]);
    }
  }
  viewer.scene.requestRender();
}
