import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material UI
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

// Component
import LargeCustomDialog from "components/Dialogs/CustomDialogs/LargeCustomDialog";
import CustomDialogTitle from "components/_universal/CustomDialogTitle/CustomDialogTitle";
import { CookieSettingsDialogSection } from "../CookieSettingsDialogSection/CookieSettingsDialogSection";
import { ReadMoreTextWrapper } from "library/ReadMoreTextWrapper";

// i18n
import { useTranslation } from "react-i18next";
import CustomDialogContent from "components/Dialogs/CustomDialogs/CustomDialogContent";

// Context
import { useCookies } from "context/CookiesContext";

// Constants
import { CookieState } from "assets/global";

/**
 * CookieSettingsDialog component renders a dialog for managing cookie consent settings.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Determines if the dialog is open.
 * @param {Function} props.onClose - Callback function to handle closing the dialog.
 *
 * @returns {React.ReactElement} The rendered CookieSettingsDialog component.
 */
export function CookieSettingsDialog({ open, onClose }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { cookieConsentChoice, acceptCookieConsent, declineCookieConsent } =
    useCookies();
  const [newCookieState, setNewCookieState] = useState(cookieConsentChoice);

  const handleCloseDialog = () => {
    // Clear the state of the CookieState if it was changed
    if (newCookieState !== cookieConsentChoice) {
      setNewCookieState(cookieConsentChoice);
    }
    onClose();
  };

  const handleRejectAll = () => {
    declineCookieConsent();
    handleCloseDialog();
  };

  const handleSavePreferences = () => {
    if (newCookieState === CookieState.ACCEPTED_ALL) {
      acceptCookieConsent();
    } else {
      declineCookieConsent();
    }
    handleCloseDialog();
  };

  const handleAcceptAll = () => {
    acceptCookieConsent();
    handleCloseDialog();
  };

  useEffect(() => {
    setNewCookieState(cookieConsentChoice);
  }, [cookieConsentChoice]);

  return (
    <LargeCustomDialog
      PaperProps={{
        style: {
          maxWidth: "700px",
          overflowX: "hidden"
        }
      }}
      open={open}
      onClose={handleCloseDialog}
    >
      <CustomDialogTitle id="form-dialog-title">
        {t("cookies.DialogTitle")}
      </CustomDialogTitle>
      <CustomDialogContent
        style={{
          overflowY: "auto",
          overflowX: "hidden"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: theme.spacing(2)
          }}
        >
          <Typography variant="body1">
            {t("cookies.DialogSubtitle1")}
          </Typography>
          <ReadMoreTextWrapper
            variant="body1"
            amountOfWords={25}
            text={[
              t("cookies.DialogSubtitle2"),
              t("cookies.DialogSubtitle3"),
              t("cookies.DialogSubtitle4")
            ]}
          />
        </div>
        <Divider style={{ margin: `${theme.spacing(2)}px 0` }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
            marginTop: theme.spacing(2)
          }}
        >
          <CookieSettingsDialogSection
            title={t("cookies.DialogSectionNecessaryTitle")}
            description={t("cookies.DialogSectionNecessaryDescription")}
            type="always-enabled"
          />
          <CookieSettingsDialogSection
            title={t("cookies.DialogSectionAnalyticsTitle")}
            description={t("cookies.DialogSectionAnalyticsDescription")}
            type="toggleable"
            isEnabled={newCookieState === CookieState.ACCEPTED_ALL}
            onToggle={checked =>
              setNewCookieState(
                checked ? CookieState.ACCEPTED_ALL : CookieState.NECESSARY_ONLY
              )
            }
          />
        </div>
      </CustomDialogContent>
      <DialogActions
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 2fr 1fr",
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(1)
        }}
      >
        <Button variant="contained" color="primary" onClick={handleRejectAll}>
          {t("cookies.DialogRejectAll")}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleSavePreferences}
        >
          {t("cookies.DialogSavePreferences")}
        </Button>
        <Button variant="contained" color="primary" onClick={handleAcceptAll}>
          {t("cookies.DialogAcceptAll")}
        </Button>
      </DialogActions>
    </LargeCustomDialog>
  );
}

CookieSettingsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};
