// import i18n from "i18n";

/**
 * Method that given a string with the code name for
 * a section type, returns its long name.
 *
 * @param {String} code String code for the section type.
 * @returns {String}
 */
function getSectionTypeName(code) {
  switch (code) {
    case "O":
      return "Overhead";
    // return i18n.t("Technologies.Overhead");

    case "U":
      return "Earth Cable";
    // return i18n.t("Technologies.Trench");

    case "T":
      return "Tunnel";
    // return i18n.t("Technologies.Tunnel");

    default:
      break;
  }
}

/** 
* Method that given a numeric code for
* a section type, returns its long name.
*
* @param {Number} code Numeric code for the section type.

* @returns {String}
*/
function getSectionNameByCode(code) {
  switch (code) {
    case 1:
      return "Overhead";
    // return i18n.t("Technologies.Overhead");

    case 2:
      return "Earth Cable";
    // return i18n.t("Technologies.Trench");

    case 3:
      return "Tunnel";
    // return i18n.t("Technologies.Tunnel");

    default:
      break;
  }
}

/**
 * Method that returns an array of technology variants given a path and its
 * metadata. It iterates the given path metadata keeping an array of variants by
 * adding it each time the variant changes in the metadata.
 *
 * The returning object (in the array) have the following structure:
 *
 * {
 *      coordinates,
 *      start,
 *      end,
 *      pathId,
 *      metadata,
 *      type,
 *      name,
 *      variant
 *   }
 *
 * @param {Object} path
 * @param {Object} metadata
 * @returns {Array}
 */
function getVariants(path, metadata) {
  let coordinates = path.path.coordinates;
  let md = metadata["root"];
  let variantName = null;
  let technologyName = null;
  let sliceFrom = 0;

  let variants = [];
  // First type
  variantName = md[0]["node"]["VARIANT"];
  technologyName = md[0]["node"]["TECH"];

  let iNode = 0;
  for (; iNode < coordinates.length - 1; iNode++) {
    const currentVariant = md[iNode]["node"]["VARIANT"];
    const currentTechnology = md[iNode]["node"]["TECH"];

    // End of a section
    if (currentVariant != variantName || currentTechnology != technologyName) {
      const meta = md.slice(sliceFrom, iNode + 1);
      const coords = coordinates.slice(sliceFrom, iNode + 1);
      variants.push({
        coordinates: coords,
        start: sliceFrom,
        end: iNode,
        pathId: path.id,
        metadata: meta,
        type: meta[0].node.TYPE,
        name: meta[0].node.TECH,
        variant: meta[0].node.VARIANT
      });

      // Ready for the next
      sliceFrom = iNode;
      variantName = currentVariant;
      technologyName = currentTechnology;
    }
  }
  // Last segment
  const coords = coordinates.slice(sliceFrom, iNode + 2);
  const meta = md.slice(sliceFrom, iNode + 2);
  variants.push({
    coordinates: coords,
    start: sliceFrom,
    end: coordinates.length - 1,
    pathId: path.id,
    metadata: meta,
    type: meta[0].node.TYPE,
    name: meta[0].node.TECH,
    variant: meta[0].node.VARIANT
  });

  // Return an array with the technologies for the
  // section and the updated technology index.
  return variants;
}

/**
 * Method that, given a path and its metadata, a start and an end point, iterates
 * the section and return an array with the technologies that form the section.
 *
 * The index for the technologies in the section starts with the idx passed in
 * the parameters.
 *
 * The section object contains the following properties:
 *  - idx: Index of the section.
 *  - coordinates: Set of nodes (coordinates) for the geometry of the section.
 *  - type: Type of the section (O, U, T, ...).
 *  - start: Node where the technology starts.
 *  - end: Node where the technology ends.
 *  - pathId: Id of the path containing the section.
 *  - sectionIdx: Index of the section where the technology belongs.
 *
 * @param {Object} path // The path where the technology belongs.
 * @param {Object} metadata // The metadata of the path.
 * @param {Number} sectionIdx // The inex of the section where the technology belongs.
 * @param {String} sectionType // The type of the section (O, U, T,...)
 * @param {Number} start // The start node for the section.
 * @param {Number} end // The end node for the section.
 * @param {Number} idx // The initial index for the technology.
 *
 * @returns {Array} [technologies, index]
 */
function _getSectionTechnologies(
  path,
  metadata,
  sectionIdx,
  sectionType,
  start,
  end,
  idx
) {
  const attribute = "TECH";
  const sectionData = "SECTION_TECH_DATA";
  let coordinates = path.path.coordinates.slice(start, end + 1);
  let md = metadata["root"].slice(start, end + 1);
  let technologyName = null;
  let sliceFrom = 0;
  let segmentIdx = idx;

  let technologies = [];
  // First type
  technologyName = md[0]["node"][attribute];
  let iNode = 0;
  for (; iNode < coordinates.length - 1; iNode++) {
    const currentName = md[iNode]["node"][attribute];

    // End of a section
    if (currentName != technologyName) {
      technologies.push({
        idx: segmentIdx,
        coordinates: coordinates.slice(sliceFrom, iNode + 1),
        start: sliceFrom,
        end: iNode,
        name: technologyName,
        type: getSectionTypeName(sectionType),
        pathId: path.id,
        sectionIdx: sectionIdx,
        data: md[sliceFrom]["node"][sectionData],
        metadata: md.slice(sliceFrom, iNode + 1)
      });

      // Ready for the next
      segmentIdx++;
      sliceFrom = iNode;
      technologyName = currentName;
    }
  }
  // Last segment
  technologies.push({
    idx: segmentIdx,
    coordinates: coordinates.slice(sliceFrom, iNode + 2),
    name: technologyName,
    type: getSectionTypeName(sectionType),
    start: sliceFrom,
    end: coordinates.length - 1,
    pathId: path.id,
    sectionIdx: sectionIdx,
    data: md[sliceFrom]["node"][sectionData],
    metadata: md.slice(sliceFrom, iNode + 2)
  });

  // Return an array with the technologies for the
  // section and the updated technology index.
  return [technologies, segmentIdx];
}

/**
 * Method that, given a path and its metadata, iterates the path and return
 * an array with the sections that form the path and the technologies
 * for each section.
 *
 * The section object contains the following properties:
 *  - idx: Index of the section.
 *  - coordinates: Set of nodes (coordinates) for the geometry of the section.
 *  - start: Node where the section starts.
 *  - end: Node where the section ends.
 *  - type: Type of the section (O, U, T, ...).
 *  - pathId: Id of the path containing the section.
 *  - technologies: Array with the technologies in the section.
 *
 * @param {Object} path
 * @param {Object} metadata
 * @returns {Array}
 */
function getPathSections(path, metadata) {
  const attribute = "TYPE";
  let coordinates = path.path.coordinates;
  let md = metadata["root"];
  let sectionType = null;
  let sliceFrom = 0;
  let sectionIdx = 0;
  let technologyIdx = 0;

  let sections = [];
  // First type
  sectionType = md[0]["node"][attribute];
  let iNode = 0;

  for (; iNode < coordinates.length - 1; iNode++) {
    const currentType = md[iNode]["node"][attribute];
    // End of a section
    if (currentType != sectionType) {
      const [technologies, tIdx] = _getSectionTechnologies(
        path,
        metadata,
        sectionIdx,
        sectionType,
        sliceFrom,
        iNode,
        technologyIdx
      );
      sections.push({
        idx: sectionIdx,
        coordinates: coordinates.slice(sliceFrom, iNode + 1),
        start: sliceFrom,
        end: iNode,
        type: sectionType,
        pathId: path.id,
        technologies: technologies,
        metadata: { root: metadata.root.slice(sliceFrom, iNode) }
      });

      // Ready for the next
      technologyIdx = tIdx + 1;
      sectionIdx++;
      sliceFrom = iNode;
      sectionType = currentType;
    }
  }
  // Last segment
  const [technologies] = _getSectionTechnologies(
    path,
    metadata,
    sectionIdx,
    sectionType,
    sliceFrom,
    iNode + 1,
    technologyIdx
  );

  sections.push({
    idx: sectionIdx,
    coordinates: coordinates.slice(sliceFrom, iNode + 2),
    start: sliceFrom,
    end: coordinates.length - 1,
    type: sectionType,
    pathId: path.id,
    technologies: technologies,
    metadata: { root: metadata.root.slice(sliceFrom, iNode + 1) }
  });
  return sections;
}

/**
 * Method that, given a path, its metadata, and a section index,
 * retrieves the sections of the path and returns the section corresponding to
 * the index passed in the params.
 *
 * The section object contains the following properties:
 *  - idx: Index of the section.
 *  - coordinates: Set of nodes (coordinates) for the geometry of the section.
 *  - start: Node where the section starts.
 *  - end: Node where the section ends.
 *  - type: Type of the section (O, U, T, ...).
 *  - pathId: Id of the path containing the section.
 *  - technologies: Array with the technologies in the section.
 *
 * @param {Object} path
 * @param {Object} metadata
 * @param {Number} sectionIdx
 * @returns {Object}
 */
function getPathSection(path, metadata, sectionIdx) {
  const sections = getPathSections(path, metadata);
  const section = sections.find(item => item.idx === sectionIdx);
  return section;
}

/**
 * Method that returns an array of technologies given a path and its
 * metadata. It looks for the sections of the path and then flattens
 * its technologies property into a single array.
 *
 * @param {Object} path
 * @param {Object} metadata
 * @returns {Array}
 */
function getPathTechnologies(path, metadata) {
  const sections = getPathSections(path, metadata);
  let technologies = [];

  for (const section of sections) {
    technologies = [...technologies, ...section.technologies];
  }
  return technologies;
}

/**
 * Method that returns a technology given a path, its metadata and
 * the index of the technology to retrieve.
 *
 * @param {Object} path
 * @param {Object} metadata
 * @param {Number} technolgyIdx
 * @returns {Object}
 */
function getPathTechnology(path, metadata, technolgyIdx) {
  const technologies = getPathTechnologies(path, metadata);
  const technology = technologies.find(item => item.idx === technolgyIdx);
  return technology;
}

/**
 * Method that returns an object with the 3 types of editable nodes
 * given a path, its metadata and the index of the technology
 * to retrieve.
 *
 * {
 *  all: [] <-- All nodes.
 *  split: [] <-- Nodes available to split.
 *  join: [] <-- Nodes available to join.
 * }
 *
 * @param {object} path
 * @param {object} metadata
 * @returns {object}
 */
function getNodes(path, metadata) {
  let coordinates = path?.path?.coordinates;
  let md = metadata["root"];
  let technologyName = null;
  let technologyType = null;
  let technologyVariant = null;

  let splitNodes = [];
  let joinNodes = [];
  let allNodes = [];

  // First type
  technologyName = md[0]["node"]["TECH"];
  technologyType = md[0]["node"]["TYPE"];
  technologyVariant = md[0]["node"]["VARIANT"];

  let iNode = 0;
  let iSection = 0;
  let iTechnology = 0;
  let iVariant = 0;

  for (; iNode < coordinates.length; iNode++) {
    const currentName = md[iNode]["node"]["TECH"];
    const currentType = md[iNode]["node"]["TYPE"];
    const currentVariant = md[iNode]["node"]["VARIANT"];

    if (technologyType != currentType) {
      technologyType = currentType;
      iSection++;
      iTechnology = -1;
    }

    if (technologyVariant != currentVariant) {
      technologyVariant = currentVariant;
      iVariant++;
    }

    // End of a technology
    if (currentName != technologyName) {
      technologyName = currentName;
      iTechnology++;

      joinNodes.push({
        idx: iNode,
        coordinates: coordinates[iNode],
        technology: technologyName,
        type: technologyType,
        variant: technologyVariant,
        pathId: path.id,
        sectionIdx: iSection,
        technologyIdx: iTechnology,
        variantIdx: iVariant
      });

      allNodes.push({
        idx: iNode,
        coordinates: coordinates[iNode],
        technology: technologyName,
        type: technologyType,
        variant: technologyVariant,
        pathId: path.id,
        sectionIdx: iSection,
        technologyIdx: iTechnology,
        variantIdx: iVariant
      });

      // Ready for the next
    } else {
      splitNodes.push({
        idx: iNode,
        coordinates: coordinates[iNode],
        technology: technologyName,
        type: technologyType,
        variant: technologyVariant,
        pathId: path.id,
        sectionIdx: iSection,
        technologyIdx: iTechnology,
        variantIdx: iVariant
      });

      allNodes.push({
        idx: iNode,
        coordinates: coordinates[iNode],
        technology: technologyName,
        type: technologyType,
        variant: technologyVariant,
        pathId: path.id,
        sectionIdx: iSection,
        technologyIdx: iTechnology,
        variantIdx: iVariant
      });
    }
  }

  // Return an object with the 2 types of nodes, the ones
  // available to split and the ones to join.
  return { split: splitNodes, join: joinNodes, all: allNodes };
}

/**
 * Method that given a path metadata and a node index returns an
 * object containing the index of the previous node, its technology
 * and its type.
 *
 * {
 *  idx,
 *  technologyName,
 *  techologyType
 * }
 *
 * @param {object} metadata
 * @param {number} nodeIdx
 * @returns object
 */
function getPreviousNode(metadata, nodeIdx) {
  let md = metadata["root"];
  let technologyName = null;

  technologyName = md[nodeIdx - 1]["node"]["TECH"];
  const technologyType = md[nodeIdx - 1]["node"]["TYPE"];

  return {
    idx: nodeIdx - 1,
    technologyName: technologyName,
    technologyType: technologyType
  };
}

/**
 * Method that given a path, its metadata and a node index returns an
 * object containing the index of the node, its technology its type
 * and 2 arrays with its previous and next nodes until the next
 * change of technology.
 *
 * {
 *  idx,
 *  technologyName,
 *  techologyType,
 *  prevSegment,
 *  nextSegment
 * }
 *
 * @param {object} path
 * @param {object} metadata
 * @param {number} nodeIdx
 * @returns object
 */
function getNodeNeighbours(path, metadata, nodeIdx) {
  let coordinates = path.path.coordinates;
  let md = metadata["root"];
  let technologyName = null;

  // Find previous nodes until technology changes or
  // the path starts.
  let iNode = nodeIdx;
  technologyName = md[nodeIdx]["node"]["TECH"];
  const technologyType = md[nodeIdx]["node"]["TYPE"];
  let currentName = technologyName;
  while (currentName === technologyName && iNode >= 0) {
    currentName = md[iNode]["node"]["TECH"];
    iNode--;
  }
  const prevSegment = [iNode === -1 ? 0 : iNode + 2, nodeIdx];

  // Find next nodes until technology changes or
  // the path ends.
  iNode = nodeIdx;
  currentName = technologyName;
  while (currentName === technologyName && iNode < coordinates.length) {
    currentName = md[iNode]["node"]["TECH"];
    iNode++;
  }
  const nextSegment = [
    nodeIdx,
    iNode === coordinates.length ? coordinates.length - 1 : iNode - 2
  ];

  return {
    idx: nodeIdx,
    technologyName: technologyName,
    technologyType: technologyType,
    prevSegment: prevSegment,
    nextSegment: nextSegment
  };
}

/**
 * Method that given a path, its metadata and a node index returns an
 * object containing the index of the node, its technology and 2 arrays
 * with its previous and next nodes until the next change of technology.
 *
 * {
 *  idx,
 *  technologyName,
 *  prevSegment,
 *  nextSegment
 * }
 *
 * @param {object} path
 * @param {object} metadata
 * @param {number} nodeIdx
 * @returns object
 */
function getJointNeighbours(path, metadata, nodeIdx) {
  const attribute = "TECH";
  let coordinates = path.path.coordinates;
  let md = metadata["root"];
  let prevTechnologyName = null;
  let nextTechnologyName = null;
  let prevTechnologyVariant = null;
  let nextTechnologyVariant = null;
  let technologyName = md[nodeIdx]["node"][attribute];
  let technologyType = md[nodeIdx]["node"]["TYPE"];
  let technologyVariant = md[nodeIdx]["node"]["VARIANT"];

  // Find NEXT join nodes.
  nextTechnologyName = md[nodeIdx]["node"][attribute];
  let currentName = nextTechnologyName;
  let iNode = nodeIdx;
  let nextSegment = [];

  if (technologyType === "T") {
    // Case when clicked join node is a Tunnel, so no next
    // nodes here, just the clicked one.
    nextSegment = [nodeIdx, nodeIdx];
  } else {
    // Case for non-tunnel types.
    iNode = nodeIdx + 1;
    nextTechnologyName = md[iNode]["node"][attribute];

    if (nextTechnologyName !== currentName) {
      // Case when the current sengment only has the clicked
      // node, and the next one is a new segment.
      nextSegment = [nodeIdx, nodeIdx];
      nextTechnologyName = md[nodeIdx]["node"][attribute];
      nextTechnologyVariant = md[nodeIdx]["node"]["VARIANT"];
    } else {
      // Rest of the cases, when current segment has more than
      // the clicked node.
      currentName = nextTechnologyName;
      nextTechnologyVariant = md[iNode]["node"]["VARIANT"];
      while (currentName === nextTechnologyName && iNode < coordinates.length) {
        currentName = md[iNode]["node"][attribute];
        iNode++;
      }
      nextSegment = [
        nodeIdx,
        iNode === coordinates.length ? coordinates.length - 1 : iNode - 2
      ];
    }
  }

  let prevSegment = [];

  // Find PREVIOUS nodes until technology changes or
  // the path starts.
  iNode = nodeIdx - 1;
  prevTechnologyName = md[iNode]["node"][attribute];
  prevTechnologyVariant = md[iNode]["node"]["VARIANT"];

  currentName = prevTechnologyName;
  while (currentName === prevTechnologyName && iNode >= 0) {
    currentName = md[iNode]["node"][attribute];
    iNode--;
  }
  prevSegment = [iNode === -1 ? 0 : iNode + 2, nodeIdx];

  return {
    idx: nodeIdx,
    technologyName: technologyName,
    techVariant: technologyVariant,
    prevSegment: prevSegment,
    prevTechnologyName: prevTechnologyName,
    prevTechnologyVariant: prevTechnologyVariant,
    nextSegment: nextSegment,
    nextTechnologyName: nextTechnologyName,
    nextTechnologyVariant: nextTechnologyVariant
  };
}

/**
 * Method that given a path, its metadata and a node index, returns the node metadata.
 * @param {object} path
 * @param {object} metadata
 * @param {number} nodeIdx
 * @returns object
 */
function getNode(path, metadata, nodeIdx) {
  const nodes = getNodes(path, metadata)?.all;
  return nodes[nodeIdx];
}

function getTechnologyLeadNode(path, metadata, technologyIdx) {
  const attribute = "TECH";
  let coordinates = path.path.coordinates;
  let md = metadata["root"];
  let technologyName = null;
  let idx = 0;
  let sliceFrom = 0;

  // First type
  technologyName = md[0]["node"][attribute];
  let iNode = 0;
  for (; iNode < coordinates.length - 1; iNode++) {
    if (technologyIdx === idx) {
      return sliceFrom;
    }
    const currentName = md[iNode]["node"][attribute];

    // End of a section
    if (currentName != technologyName) {
      idx++;
      technologyName = currentName;
      sliceFrom = iNode;
    }
  }
}

export {
  getSectionTypeName,
  getSectionNameByCode,
  getPathSections,
  getPathSection,
  getPathTechnologies,
  getPathTechnology,
  getNodes,
  getPreviousNode,
  getNodeNeighbours,
  getJointNeighbours,
  getNode,
  getVariants
};
