// Utils
import {
  addImageryLayer,
  changeImageryLayerOpacityByName,
  loadTMSWithColor,
  removeImageryLayerByName
} from "utils/Cesium/CesiumImageryUtils";

// Services
import { getPalette } from "services/layer";

// Other
import { comparisonMapLayerName } from "assets/global";

export async function cesiumAddComparisonMap(viewer, comparisonMap, opacity) {
  const palette = await getPalette("ana-op2-full-3zones");

  let layerName = comparisonMapLayerName + comparisonMap.id;
  if (comparisonMap.tms_tiles) {
    // Load Tile Map Service
    const payload = {
      layerId: comparisonMap.id,
      viewer: viewer,
      gradient: palette,
      tmsUrl: comparisonMap.tms_tiles,
      name: layerName,
      extent: comparisonMap.png_bounds,
      color: null,
      source: "comparisonMap",
      alpha: opacity
    };
    loadTMSWithColor(payload);
  } else {
    addImageryLayer(viewer, layerName, comparisonMap, opacity);
  }
}

export function cesiumRemoveComparisonMap(viewer, comparisonMapId) {
  removeImageryLayerByName(viewer, comparisonMapLayerName + comparisonMapId);
}

export function cesiumSetComparisonMapOpacity(viewer, comparisonMap, opacity) {
  changeImageryLayerOpacityByName(
    viewer,
    comparisonMapLayerName + comparisonMap.id,
    opacity
  );
}
