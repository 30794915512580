// Utils
import {
  addImageryLayer,
  loadTMSWithColor,
  changeImageryLayerOpacityByName,
  removeImageryLayerByName
} from "utils/Cesium/CesiumImageryUtils";

// Services
import { getPalette } from "services/layer";

// Other
import { corridorLayerName } from "assets/global";

export async function cesiumAddCorridor(viewer, corridor, opacity) {
  const palette = await getPalette("Corridor");

  let layerName = corridorLayerName + corridor.id;
  if (corridor.tms_tiles) {
    // Load Tile Map Service
    const payload = {
      layerId: corridor.id,
      viewer: viewer,
      gradient: palette,
      tmsUrl: corridor.tms_tiles,
      name: layerName,
      extent: corridor.png_bounds,
      color: null,
      source: "corridor",
      alpha: opacity
    };
    loadTMSWithColor(payload);
  } else {
    addImageryLayer(viewer, layerName, corridor, opacity);
  }
}

export function cesiumRemoveCorridor(viewer, corridorId) {
  removeImageryLayerByName(viewer, corridorLayerName + corridorId);
}

export function cesiumSetCorridorOpacity(viewer, corridor, opacity) {
  changeImageryLayerOpacityByName(
    viewer,
    corridorLayerName + corridor.id,
    opacity
  );
}
