import React from "react";
import PropTypes from "prop-types";

// Material UI
import DialogContent from "@material-ui/core/DialogContent";

// Styles
import useStyles from "./styles.contentFullScreen";

/**
 * Custom component to be used for all dialog content.
 * The component acts as a wrapper of the material-ui DialogContent component,
 * ensuring that all dialog content has consistent styling across the app.
 *
 * To use it, simply render it nested inside a SmallCustomDialog or
 * LargeCustomDialog component. Any additional props on this component
 * will be passed on to the wrapped DialogContent component.
 * @param {Object} params
 * @param {JSX.Element | JSX.Element[]} params.children - Children element to render
 * @param {string} [params.className] - Optional class name to ad to the root dialog content div
 * @returns {JSX.Element}
 */
function CustomDialogContent({ children, className, ...other }) {
  const classes = useStyles();
  return (
    <DialogContent
      classes={{ root: `${classes.dialogContentFullScreen} ${className}` }}
      {...other}
    >
      {children}
    </DialogContent>
  );
}

CustomDialogContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  className: PropTypes.string
};

export default CustomDialogContent;
