// Cesium
import {
  Cesium3DTileset,
  Cartographic,
  when,
  EllipsoidTerrainProvider,
  sampleTerrainMostDetailed,
  defined,
  Cartesian3,
  Matrix4
} from "cesium/Build/Cesium/Cesium";

/**
 * Find 3D tiles in Scene by name.
 *
 * @param {object} viewer Cesium viewer instance.
 * @param {string} name Name of the tileset.
 */
export function find3DTilesByName(viewer, name) {
  let primitives = viewer.scene.primitives;
  let length = primitives.length;
  for (let i = 0; i < length; ++i) {
    let p = primitives.get(i);
    if (p instanceof Cesium3DTileset) {
      if (p.name === name) {
        return [true, p];
      }
    }
  }
  return [false, null];
}

/**
 * Remove 3D tiles in Scene by name.
 *
 * @param {object} viewer Cesium viewer instance.
 * @param {string} name Name of the tileset.
 */
export function remove3DTilesByName(viewer, name) {
  let primitives = viewer.scene.primitives;
  let length = primitives.length;
  for (let i = 0; i < length; ++i) {
    let p = primitives.get(i);
    if (p instanceof Cesium3DTileset) {
      if (p.name === name) {
        primitives.remove(p);
      }
    }
  }
}

/**
 * Update 3D Tileset.
 *
 * @param {object} viewer Cesium viewer instance.
 * @param {object} tileset The target tileset.
 */
export function updateTileset(viewer, tileset) {
  let root = tileset.root;
  if (root === undefined) {
    return;
  }
  if (root.contentReady) {
    updateTile(viewer, root);
  } else {
    let listener = tileset.tileLoad.addEventListener(tile => {
      if (tile === root) {
        updateTile(viewer, tile);
        listener();
      }
    });
  }

  let children = root.children;
  let length = children.length;
  let i;
  for (i = 0; i < length; ++i) {
    updateTile(viewer, children[i]);
  }
}

/**
 * Update height 3D Tile.
 *
 * @param {object} viewer Cesium viewer instance.
 * @param {object} tileset The target tileset.
 */
export function updateTile(viewer, tileset) {
  let scene = viewer.scene;
  let content = tileset.content;
  let model = content._model;

  let center = tileset.boundingSphere.center;
  let carto = Cartographic.fromCartesian(center);

  let promise = when.defer();
  if (scene.terrainProvider instanceof EllipsoidTerrainProvider) {
    let result = carto;
    result.height = 0;
    promise.resolve(result);
  } else {
    promise = sampleTerrainMostDetailed(scene.terrainProvider, [carto]).then(
      results => {
        let result = results[0];
        if (!defined(result)) {
          return carto;
        }
        return result;
      }
    );
  }

  promise.then(result => {
    result = Cartographic.toCartesian(result);
    let surface = Cartesian3.fromRadians(
      result.longitude,
      result.latitude,
      0.0
    );
    let offset = Cartesian3.fromRadians(
      result.longitude,
      result.latitude,
      result.height
    );
    let translation = Cartesian3.subtract(offset, surface, new Cartesian3());
    model.modelMatrix = Matrix4.fromTranslation(translation);
  });
}
