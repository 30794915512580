import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import useStyles from "./styles";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
// Translation
import { useTranslation } from "react-i18next";

const ExitButton = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onClose } = props;
  return (
    <Tooltip
      enterNextDelay={500}
      disableFocusListener
      enterDelay={800}
      title={t("common.Exit")}
      placement="bottom"
      aria-label="Exit"
    >
      <button
        type="button"
        className={classNames(
          "close",
          classes.close,
          !props?.disableDefaultPosition
            ? classes.closeButtonDefaultPosition
            : classes.closeButtonNonDefaultPosition
        )}
        data-dismiss="alert"
        aria-label="Close"
        onClick={onClose}
        style={props?.style}
      >
        <CloseIcon />
      </button>
    </Tooltip>
  );
};
ExitButton.propTypes = {
  /** function prop */
  onClose: PropTypes.func,
  /** boolean prop to disable using the position absolute by default */
  disableDefaultPosition: PropTypes.bool,
  /** style prop */
  style: PropTypes.object
};

ExitButton.defaultProps = {
  onClose: () => {},
  disableDefaultPosition: false
};
export default ExitButton;
