import React from "react";

// Third-party
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import { CookiesProvider } from "context/CookiesContext";

// Material UI
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {
  StylesProvider,
  createGenerateClassName,
  ThemeProvider
} from "@material-ui/core/styles";
import theme from "./theme";

// Components
import App from "./App";

// Context
import AuthProvider from "./AuthProvider";
import AppProvider from "AppProvider";
import LocalSettingsProvider from "context/LocalSettingsContext";
import CommentsProvider from "context/CommentsContext/";
import CapabilitiesProvider from "context/CapabilitiesContext/";

// Other
import "./index.css";
import "./i18n";
import getenvvar from "utils/Env";
import * as serviceWorker from "./serviceWorker";
import CostModelsProvider from "context/CostModelsContext";

// Constants
const Sentry_DSN = getenvvar("REACT_APP_API_SENTRY_DSN");
const Sentry_Release = getenvvar("REACT_APP_API_SENTRY_RELEASE");
const generateClassName = createGenerateClassName({
  productionPrefix: "path"
});

// Notistack Config
const notistackRef = React.createRef();
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
};

// Sentry Initialization
Sentry.init({
  dsn: Sentry_DSN || null,
  integrations: [new Integrations.BrowserTracing({ tracingOrigins: ["*"] })],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  release: Sentry_Release
});

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <CookiesProvider>
          <AuthProvider>
            <CapabilitiesProvider>
              <BrowserRouter>
                <SnackbarProvider
                  classes={{
                    containerRoot: "snackbar-container"
                  }}
                  preventDuplicate
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                  }}
                  ref={notistackRef}
                  action={key => (
                    <IconButton
                      color="inherit"
                      size="small"
                      onClick={onClickDismiss(key)}
                      aria-label="close"
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  )}
                >
                  <LocalSettingsProvider>
                    <CommentsProvider>
                      <AppProvider>
                        <CostModelsProvider>
                          <App />
                        </CostModelsProvider>
                      </AppProvider>
                    </CommentsProvider>
                  </LocalSettingsProvider>
                </SnackbarProvider>
              </BrowserRouter>
            </CapabilitiesProvider>
          </AuthProvider>
        </CookiesProvider>
      </ThemeProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
export { theme };
