import {
  GOOGLE_ANALYTICS_ID_KEY,
  CookieState,
  COOKIE_CONSENT_KEY,
  HOTJAR_SCRIPT_ID
} from "assets/global";
import getenvvar from "utils/Env";
import ReactGA from "react-ga4";

const GOOGLE_ANALYTICS_ID = getenvvar(GOOGLE_ANALYTICS_ID_KEY);

/**
 * Retrieves the cookie consent state from localStorage and maps it to a safe value.
 *
 * The function checks the stored value for cookie consent and maps it to one of the
 * predefined states in the CookieState enum. It also supports legacy values for backward
 * compatibility.
 *
 * @returns {CookieState} The safe cookie consent state.
 */
export function getCookieConsent() {
  const candidateValue = localStorage.getItem(COOKIE_CONSENT_KEY);
  let safeValue = CookieState.UNKNOWN;

  switch (candidateValue) {
    case "declined": // Support for old "declined" value, after some time we can remove this
      safeValue = CookieState.NECESSARY_ONLY;
      break;
    case CookieState.NECESSARY_ONLY:
      safeValue = CookieState.NECESSARY_ONLY;
      break;
    case "accepted": // Support for old "accepted" value, after some time we can remove this
      safeValue = CookieState.ACCEPTED_ALL;
      break;
    case CookieState.ACCEPTED_ALL:
      safeValue = CookieState.ACCEPTED_ALL;
      break;
    case CookieState.UNKNOWN:
      safeValue = CookieState.UNKNOWN;
      break;
    case "undecided": // Support for old "undecided" value, after some time we can remove this
      safeValue = CookieState.UNKNOWN;
      break;
    default:
      safeValue = CookieState.UNKNOWN;
      break;
  }

  return safeValue;
}

/**
 * Sets the cookie consent value in the local storage.
 *
 * @param {CookieState} consent - The consent value to be stored.
 * @remark The param value is not validated, so make sure to pass a valid value.
 */
export function setCookieConsent(consent) {
  localStorage.setItem(COOKIE_CONSENT_KEY, consent);
}

/**
 * Adds the Google Analytics script to the application.
 *
 * This function checks if Google Analytics is not already initialized and if a valid
 * Google Analytics ID is provided. If both conditions are met, it initializes Google Analytics
 * with the provided ID.
 */
function addGoogleAnalyticsScript() {
  if (
    ReactGA.isInitialized === false &&
    GOOGLE_ANALYTICS_ID &&
    GOOGLE_ANALYTICS_ID.trim() !== ""
  ) {
    // Only initialize Google Analytics if the ID is set (a valid non-empty string)
    ReactGA.initialize(GOOGLE_ANALYTICS_ID);
  }
}

/**
 * Adds the HotJar script to the document if it does not already exist.
 * The script is used for HotJar analytics and tracking.
 */
function addHotJarScript() {
  const alreadyExistsHotJarScript = !!document.getElementById(HOTJAR_SCRIPT_ID);

  if (alreadyExistsHotJarScript) return;

  const scriptHotJar = document.createElement("script");
  scriptHotJar.id = HOTJAR_SCRIPT_ID;
  scriptHotJar.type = "text/javascript";
  const scriptHotJarTextContent =
    document.createTextNode(`(function(h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function() {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 3646529, hjsv: 6 };
      a = o.getElementsByTagName("head")[0];
      r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");`);
  scriptHotJar.appendChild(scriptHotJarTextContent);

  document.body.appendChild(scriptHotJar);
}

/**
 * Removes analytics scripts from the document.
 *
 * This function performs the following actions:
 * 1. Checks if the Google Analytics (ReactGA) is initialized and resets it if true.
 * 2. Finds and removes the HotJar script from the document if it exists.
 */
export function removeAnalyticsScripts() {
  const hotJarScript = document.getElementById(HOTJAR_SCRIPT_ID);

  if (ReactGA.isInitialized) {
    ReactGA.reset();
  }

  if (hotJarScript) {
    hotJarScript.remove();
  }
}

/**
 * Adds analytics scripts to the application.
 * This function includes the Google Analytics and HotJar scripts.
 */
export function addAnalyticsScripts() {
  addGoogleAnalyticsScript();
  addHotJarScript();
}
