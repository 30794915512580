// Utils
import { getDatasourceLayerByName } from "utils/Cesium/CesiumDatasourceUtils";
import { getColorByString } from "utils/Cesium/CesiumUtils";
// Cesium
import { JulianDate, Color } from "cesium/Build/Cesium/Cesium";
// Other
import { optimalPathVectorName } from "assets/global";

/**
 * Removes a Cesium entity from a datasource by id.
 *
 * @param {object} datasource The target datasource.
 * @param {number} id The id of the entity.
 */
export function removeDatasourceEntityById(datasource, entityId) {
  const entity = datasource?.entities.getById(entityId);

  if (entity) {
    datasource.entities.remove(entity);
  }
}

/**
 * Removes a Cesium entity from a given list of entities. To select the entity
 * to remove we can pass the parameter, the value and the condition.
 *
 * @param {Cesium.Viewer} viewer The Cesium Viewer instance.
 * @param {Cesium.EntityCollection} entities The Cesium entity collection to modify.
 * @param {string} param The property name to check.
 * @param {*} value The value to compare against.
 * @param {boolean} [equal=true] True for equality check, false for inequality.
 *
 * @returns {void} Modifies the entity collection in-place.
 */
export function removeEntityByCondition(viewer, entities, param, value) {
  entities.suspendEvents();

  const entitiesToRemove = entities.values.filter(
    entity => entity[param] === value
  );

  entitiesToRemove.forEach(entity => {
    entities.remove(entity);
  });

  entities.resumeEvents();
  viewer.scene.requestRender();
}

/**
 * Method that removes entities from a Cesium entity collection by matching
 * the entity name.
 *
 * @param {Cesium.Viewer} viewer The Cesium Viewer instance.
 * @param {Cesium.EntityCollection} entities The Cesium entity collection to modify.
 * @param {string} name The name of entities to remove.
 *
 * @returns {void} Modifies the entity collection in-place
 */
export function removeEntityByName(viewer, entities, name) {
  entities.suspendEvents();

  const entitiesToRemove = entities.values.filter(
    entity => entity.name === name
  );

  entitiesToRemove.forEach(entity => {
    entities.remove(entity);
  });

  entities.resumeEvents();
  viewer.scene.requestRender();
}

/**
 * Method that removes entities form a Cesium entity collection bymatching both
 * name and a specific property value.
 *
 * @param {Cesium.Viewer} viewer The Cesium Viewer instance.
 * @param {Cesium.EntityCollection} entities The Cesium entity collection to modify.
 * @param {string} name The name of entities to remove.
 * @param {string} property The property name to check.
 * @param {*} value The expected property value to match.
 *
 * @returns {void} Modifies the entity collection in-place.
 * @throws {Error} If property access fails.
 */
export function removeEntityByNameAndProperty(
  viewer,
  entities,
  name,
  property,
  value
) {
  try {
    entities.suspendEvents();

    const entitiesToRemove = entities.values.filter(entity => {
      if (entity.name !== name) return false;

      let propValue = null;
      if (entity.properties) {
        const prop = entity.properties.getValue(viewer.clock.currentTime);
        propValue = prop[property];
      }

      return propValue === value;
    });

    entitiesToRemove.forEach(entity => {
      entities.remove(entity);
    });

    entities.resumeEvents();
    viewer.scene.requestRender();
  } catch (error) {
    console.error(error);
  }
}

/**
 * Change polyline entity material color by name. It looks for the entities
 * which name is "line" and with the given id, and applies the given
 * color as material.
 *
 * @param {Cesium.EntityCollection} entities The list of entities to work on.
 * @param {number} id The id of the entity to change color.
 * @param {string} color The new color.
 */
export function changePolylineMaterialByName(entities, id, color) {
  entities.suspendEvents();
  let i;
  let entitiesValues = entities.values;
  let l = entitiesValues.length;
  let newColor = getColorByString(color);

  for (i = 0; i < l; i++) {
    let entity = entitiesValues[i];
    // For the case of the flatline (the line for no pylons), we
    // identify it by looking fot hhe property pathId and comparing
    // with the id parameter.
    const lineProperties = entity.properties?.getValue(new JulianDate());
    const pathId = lineProperties?.pathId;

    if (entity.group === id || pathId === id) {
      if (entity.name === "earthCable" || entity.name === "tunnel") {
        entity.polylineVolume.material.color = newColor;
        if (entity.polyline.material) {
          switch (entity.polyline.material.constructor.name) {
            default:
            case "ColorMaterialProperty":
              entity.polyline.material = newColor;
              break;
            case "PolylineDashMaterialProperty":
              entity.polyline.material.color = newColor;
              break;
          }
        }
      } else if (
        entity.polyline &&
        entity.polyline?.material &&
        entity.name !== "auxline"
      ) {
        switch (entity.polyline.material.constructor.name) {
          default:
          case "ColorMaterialProperty":
            entity.polyline.material = newColor;
            break;
          case "PolylineDashMaterialProperty":
            entity.polyline.material.color = newColor;
            break;
        }
      } else if (entity.name === "transitionRectangle") {
        const material = getColorByString(color);
        entity.polygon.material.color = material;
      }
    }
  }
  entities.resumeEvents();
}

/**
 * Sets the visibility of the ellipse belonging to an
 * entity with a given name.
 *
 * @param {Cesium.Viewer} viewer The Cesium Viewer instance.
 * @param {Cesium.EntityCollection} entities The list of entities to work on.
 * @param {string} searchName The name of the entity.
 * @param {boolean} visible Value to set the show property of the ellipse.
 */
export function setEntityEllipseVisiblity(
  viewer,
  entities,
  searchName,
  visible
) {
  entities.suspendEvents();
  let i;
  let values = entities.values;
  let l = values.length;
  for (i = 0; i < l; i++) {
    if (values[i].name === searchName) values[i].ellipse.show = visible;
  }
  entities.resumeEvents();
  viewer.scene.requestRender();
}

/**
 * Sets an entity visibility by name.
 *
 * @param {Cesium.Viewer} viewer The Cesium Viewer instance.
 * @param {Cesium.EntityCollection} entities The list of entities to work on
 * @param {string} searchName The name of the entity.
 * @param {boolean} visible Value to set the show property of the ellipse.
 */
export function setEntityVisiblityByProperty(
  viewer,
  entities,
  param,
  searchName,
  visible
) {
  entities.suspendEvents();
  let i;
  let values = entities.values;
  let l = values.length;
  for (i = 0; i < l; i++) {
    if (values[i][param] === searchName) {
      values[i].show = visible;
    }
  }
  entities.resumeEvents();
  viewer.scene.requestRender();
}

/**
 * Sets the visibility of the aux line entities in the given collection.
 *
 * @param {Cesium.Viewer} viewer The Cesium Viewer instance.
 * @param {Cesium.EntityCollection} entities The list of entities to work on.
 * @param {boolean} visible Value to set the show property of the ellipse.
 */
export function setEntityAuxLineVisiblity(viewer, entities, visible) {
  entities.suspendEvents();
  let i;
  let values = entities.values;
  let l = values.length;
  for (i = 0; i < l; i++) {
    if (values[i].name === "auxline") values[i].show = visible;
  }
  entities.resumeEvents();
  viewer.scene.requestRender();
}

/**
 * Show/hide Highlight cables in optimal path (errors).
 *
 * @param {Cesium.Viewer} viewer The Cesium Viewer instance.
 * @param {boolean} visible If true, the cables will highlight.
 */
export function toggleHighlight(viewer, visible) {
  let dataSource = getDatasourceLayerByName(viewer, "optimalPathVector");
  setEntityVisiblityByProperty(
    viewer,
    dataSource.entities,
    "name",
    "Highlight",
    visible
  );
}

/**
 * Show/hide Highlight cables in optimal path by id.
 *
 * @param {Cesium.Viewer} viewer The Cesium Viewer instance.
 * @param {boolean} visible If true, the cables will highlight.
 * @param {number} pathId The path where the highlighted cables belong.
 */
export function setHighlightById(viewer, visible, pathId) {
  let dataSource = getDatasourceLayerByName(viewer, "optimalPathVector");

  setEntityVisiblityByProperty(
    viewer,
    dataSource.entities,
    "group",
    pathId,
    visible
  );
}

/**
 * Sets entity Ellipse visibility by serial (pylon number).
 *
 * @param {Cesium.Viewer} viewer The Cesium Viewer instance.
 * @param {number} pathId The path where the highlighted cables belong.
 * @param {number} serial The serial (index) of the pylon to highlight.
 * @param {boolean} visible Value to set the show property of the ellipse.
 */
export function setEntityEllipseVisiblityBySerial(
  viewer,
  pathId,
  serial,
  visible
) {
  const dataSource = getDatasourceLayerByName(viewer, optimalPathVectorName);

  const entities = dataSource.entities;

  entities.suspendEvents();

  let i;
  let values = entities.values;
  const l = values.length;
  for (i = 0; i < l; i++) {
    if (values[i]?.group === pathId) {
      if (values[i].data?.serial === serial) {
        if (values[i].ellipse?.show) values[i].ellipse.show = visible;
      }
    }
  }
  entities.resumeEvents();
  viewer.scene.requestRender();
}
