import { makeStyles } from "@material-ui/core/styles";
export default makeStyles(theme => ({
  close: {
    color: theme.palette.textOnLight.muted,
    border: 0,
    borderRadius: theme.radius.small,
    background: "transparent",
    padding: theme.spacing(0.5) + "px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.background.tint,
      color: theme.palette.textOnLight.default
    }
  },
  closeButtonDefaultPosition: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2)
  },
  closeButtonNonDefaultPosition: {
    float: "unset"
  }
}));
