import app from "./app";

/**
 * Method to retirve all technologies from the catalog.
 *
 * @returns
 */
export async function fetchTechnologies() {
  const res = await app.get("/catalogs/technology/", {});
  return res.data;
}

/**
 * Method to retirve a technology from the catalog by id.
 *
 * @param {Number} technologyId
 * @returns
 */
export async function fetchTechnology(technologyId) {
  if (technologyId) {
    const res = await app.get(`/catalogs/technology/${technologyId}/`, {});
    return res.data;
  }
}

/**
 * Method to retirve all variants from the catalog.
 *
 * @returns
 */
export async function fetchVariants() {
  const res = await app.get("/catalogs/technology-variant/", {});
  return res.data;
}
/**
 * Method to retrieve a variant from the catalog by id.
 *
 * @param {Number} variantId
 * @returns
 */
export async function fetchVariant(variantId) {
  if (variantId) {
    const res = await app.get(`/catalogs/technology-variant/${variantId}/`, {});
    return res.data;
  }
}
