import { EPSG_4326, EPSG_3857 } from "assets/global";
import {
  reprojectFeature,
  reprojectPointAsCoords
} from "components/Dashboard2D/SrcTransform";
import CustomRasterSource from "components/LeftMenu/components/OlLayerItem/CustomRasterSource";
import { apply as applyMapBoxStyle } from "ol-mapbox-style";
import OLayerCollection from "ol/Collection";
import { boundingExtent } from "ol/extent";
import * as olExtent from "ol/extent";
import Feature from "ol/Feature";
import { GeoJSON } from "ol/format.js";
import { Circle, LineString, Point } from "ol/geom";
import { Draw } from "ol/interaction";
import { defaults as defaultInteractions } from "ol/interaction";
import OlLayerGroup from "ol/layer/Group";
import OlWebGLTile from "ol/layer/Tile";
import OlMap from "ol/Map";

import { getTileProps } from "services/tilemapresource";
import {
  getPathSections,
  getPathTechnologies,
  getNodes
} from "utils/PathWrapper";

import {
  transformExtent,
  fromLonLat,
  getPointResolution,
  METERS_PER_UNIT
} from "ol/proj";
import { get as getProjection } from "ol/proj";
import { register } from "ol/proj/proj4";
import BingMaps from "ol/source/BingMaps";
import Cluster from "ol/source/Cluster.js";
import OlSourceImageStatic from "ol/source/ImageStatic";
import * as Sentry from "@sentry/browser";

import XYZ from "ol/source/XYZ";
import WMTS from "ol/source/WMTS";
import TileWMS from "ol/source/TileWMS";

import { createXYZ } from "ol/tilegrid";
import WMTSTileGrid from "ol/tilegrid/WMTS";

import proj4 from "proj4";
import { getPalette, getRasterLayerRanges } from "services/layer";
import theme from "theme";
import { hexToRgba } from "utils/ColorUtils";

/**
 * Method to add a path into the OL map. That's creating all the LineString
 * feature for the whole path, and the Point features for the nodes/pylons
 * of the path, but also create the LineString features for the sections
 * and technologies of the path.
 *
 * @param {*} res // Path object
 * @param {*} layer
 * @param {*} viewProjection
 * @param {*} model
 * @param {*} pathId
 * @param {*} scenarioId
 */
export function addPathToMap(res, layer, viewProjection, pathId, scenarioId) {
  if (layer) {
    let scenarioPath = reprojectFeature(new GeoJSON().readFeature(res.path));

    // Add features, mostly for moving and getting specific Pylon Information
    let source = layer.getSource();
    source.clear(true);
    layer.setVisible(true);
    let pointsData = [...res.path.coordinates];

    // Add PATH feature. Here we add the LineString for the whole path. We also
    // add some custom properties to the new feature:
    //
    // gIsPath: If true the feature is a path.
    // gPathId: The id of the path where the section belongs.
    scenarioPath.set("gPathId", pathId);
    scenarioPath.set("gIsPath", true);

    let generationMdataRoutingWidth =
      res?.generation_metadata?.parameters?.ROUTING_CORRIDOR_WIDTH;
    if (generationMdataRoutingWidth != undefined) {
      scenarioPath.set("gRoutingWidthM", generationMdataRoutingWidth);
    }
    source.addFeature(scenarioPath);

    // Nodes in intersections (active when splitting technologies).
    const editableNodes = getNodes(res, res.metadata);
    const splitNodes = editableNodes?.split?.map(item => item.idx);
    const joinNodes = editableNodes?.join?.map(item => item.idx);

    // Add PYLON/NODE features. Here we iterate the nodes of the path
    // to add a Point feature for each one, representing the nodes
    // of the path.
    // We also add a custom property to the new feature:
    //
    // gIsPylon: If true the feature is a pylon.
    for (let [index, val] of pointsData.entries()) {
      let pylonFeature = new Feature({
        geometry: new Point([val[0], val[1]]).transform(
          "EPSG:4326",
          viewProjection
        ),
        line_index: index,
        name: "Pylon",
        // Note: The model metadata is now in the path metadata,
        // we are removing it from here and will add it again if requested.
        // modelwires: model.metadata.wires,
        // modelvolt: model.metadata.volt,
        // modelheight: model.metadata.height,
        parent_path: layer.get("name"),
        parent_path_id: pathId,
        parent_path_scenario_id: scenarioId
      });

      // set a unique id within this path. This can be later on
      // used by the different modification tools
      pylonFeature.setId(index);

      pylonFeature.set("gIsPylon", true);
      pylonFeature.set("gPathId", pathId);
      pylonFeature.set("gNodeIdx", index);

      if (splitNodes.includes(index)) {
        pylonFeature.set("gIsSplitNode", true);
      }

      if (joinNodes.includes(index)) {
        pylonFeature.set("gIsJoinNode", true);
      }

      source.addFeature(pylonFeature);
    }

    // Add SECTION Features. Here we iterate the sections (O, T, U) retrieved
    // with the pathWrapper to add a LineString feature for each one.
    // We also add some custom properties to the new feature:
    //
    // gIsPathSection: If true the feature is a section.
    // gSetcionIdx: The index of the section.
    // gType: The type of the section (U, O or T).
    // gPathId: The id of the path where the section belongs.
    const pathSections = getPathSections(res, res.metadata);

    for (let index = 0; index < pathSections.length; index++) {
      const section = pathSections[index];

      let unprojectedFeature = new Feature({
        geometry: new LineString(section.coordinates)
      });

      let sectionFeature = reprojectFeature(unprojectedFeature);

      sectionFeature.set("gIsPathSection", true);
      sectionFeature.set("gSetcionIdx", index);
      sectionFeature.set("gType", section.type);
      sectionFeature.set("gPathId", section.pathId);

      source.addFeature(sectionFeature);
    }

    // Add TECHNOLOGY Features. Here we iterate the technologies retrieved
    // with the pathWrapper to add a LineString feature for each one.
    // We also add some custom properties to the new feature:
    //
    // gIsTechnology: If true the feature is a technology.
    // gTechnologyIdx: The index of the technology in the path.
    // gTechnologyName: The name of the technology.
    // gSetcionIdx: The index of the section where the technology belongs.
    // gPathId: The id of the path where the section belongs.
    const technologies = getPathTechnologies(res, res.metadata);
    for (let index = 0; index < technologies.length; index++) {
      const technology = technologies[index];

      let unprojectedFeature = new Feature({
        geometry: new LineString(technology.coordinates)
      });

      let technologyFeature = reprojectFeature(unprojectedFeature);

      technologyFeature.set("gIsTechnology", true);
      technologyFeature.set("gTechnologyIdx", technology.idx);
      technologyFeature.set("gTechnologyName", technology.name);
      technologyFeature.set("gSectionIdx", technology.sectionIdx);
      technologyFeature.set("gPathId", technology.pathId);

      source.addFeature(technologyFeature);
    }
  }
}

/**
 * Returns the LineString feature from a Path Layer
 *
 * @param {olVectorLayer} layer The vector layer to retrieve the source from
 *
 * @returns reference to the LineString of the layer, or undefined if none found
 */
export function getMainLineStringFromPathLayer(layer) {
  const source = getOLLayerSource(layer);
  const features = source.getFeatures();
  const linestring = features.find(
    item => item.getGeometry().getType() === "LineString" && item.get("gIsPath")
  );
  return linestring;
}

/**
 * Method to retireve the point features from a layer.
 *
 * @param {object} layer The layer to retrieve points from.
 * @param {string} [attribute] Optional attribute to retrieve only points with this custom property.
 * @returns array
 */
export function getPointsFromPathLayer(layer, attribute = null) {
  const source = getOLLayerSource(layer);
  const features = source.getFeatures();
  let points = features.filter(
    item => item.getGeometry().getType() === "Point"
  );

  if (attribute) {
    points = points.filter(item => item.get(attribute));
  }
  return points;
}

export function getLinesromPathLayer(layer, attribute = null) {
  const source = getOLLayerSource(layer);
  const features = source.getFeatures();
  let lines = features.filter(
    item => item.getGeometry().getType() === "LineString"
  );

  if (attribute) {
    lines = lines.filter(item => item.get(attribute));
  }
  return lines;
}
/**
 * Remove all map interactions
 * @param {*} map
 */
export function resetMapInteractions(map) {
  map.getInteractions().clear();
  // Set defaults Map interactions
  let defaultMapInteractions = defaultInteractions({
    altShiftDragRotate: false,
    pinchRotate: false
  });
  defaultMapInteractions.forEach(interaction => {
    map.addInteraction(interaction);
  });
}
/**
 * Get map drawing interaction
 * @returns
 */
export function getDrawInteraction(map) {
  const interactions = map.getInteractions().getArray();
  return interactions.filter(interaction => {
    return interaction instanceof Draw;
  })[0];
}

/**
 * Remove the current draw interaction if has
 * @param {*} map
 */
export function removeActiveDrawInteraction(map) {
  let activeDraw = getDrawInteraction(map);
  if (activeDraw) {
    map.removeInteraction(activeDraw);
  }
}

/**
 * Retrieve a Layer source given a Layer. This is necessary in our case
 * because the version of OL that we use does not seem to handle well
 * the `addFeature` to a Cluster Source, and we have to always retrieve
 * the original source
 *
 * @param {olVectorLayer} layer The vector layer to retrieve the source from
 *
 * @returns reference to the source of the layer
 */
export function getOLLayerSource(layer) {
  const source = layer.getSource();

  // if our source is a Cluster, we want to point at the data source itself
  // (since addFeature and such to the cluster won't work as expected)
  if (source instanceof Cluster) {
    return source.getSource();
  } else {
    return source;
  }
}

/**
 * Get Layer by name OL
 * @param {String} name Name of the layer to search for
 * @returns The OLLayer item with the given name, or undefined if none found
 */
export function getOLLayerByName(map, name) {
  let layers = getAllOLLayers(map);

  let filteredLayers = layers?.filter(layer => {
    return layer.get("name") && String(layer.get("name")) === String(name);
  });

  if (filteredLayers.length > 0) {
    return filteredLayers[0];
  } else {
    return undefined;
  }
}

/**
 * copy feature parameters (keeping the geometry)
 *
 * @param {Feature} from Feature to copy from
 * @param {Feature} to Feature to copy to
 * @returns
 */
export function copyFeatureParameters(from, to) {
  const propsArray = Object.entries(from.getProperties());
  const propsArrayFiltered = propsArray.filter(
    ([key, value]) => key !== "geometry"
  );
  to.setProperties(Object.fromEntries(propsArrayFiltered));
}

/**
 * Load an array of Objects representing GeoJson points into a given Layer
 * Supports Layers with Cluster sources too.
 *
 * @param {ol/Map} map OpenLayers Map to search the layer to add points to
 * @param {Array} points Array of Objects representing a GeoJson point, assumes
 *                SRID to be 4326 (https://www.rfc-editor.org/rfc/rfc7946#section-4)
 * @param {string} layerName Name of the layer to load the points to
 * @param {bool} overwrite Overwrite all data in the provided Layer
 *
 */
export function loadPointListToLayer(
  map,
  points,
  layerName = "commentsLayer",
  overwrite = false
) {
  const layer = getOLLayerByName(map, layerName);
  const reader = new GeoJSON();
  const source = getOLLayerSource(layer);

  if (overwrite) {
    source.clear();
  }

  const features = points.map(item =>
    reader.readFeature(item, {
      dataProjection: "EPSG:4326",
      featureProjection: map.getView().getProjection()
    })
  );

  source.addFeatures(features);
}

/**
 * Get all map layers
 * @param {*} collection
 * @param {*} filter
 * @returns
 */
export function getAllOLLayers(collection, filter = () => true) {
  if (!(collection instanceof OlMap) && !(collection instanceof OlLayerGroup)) {
    console.log(
      "Input parameter collection must be from type `ol/Map`" +
        "or `ol/layer/Group`."
    );
    return [];
  }

  var layers = collection.getLayers().getArray();

  return layers.flatMap(layer => {
    let layers = [];
    if (layer instanceof OlLayerGroup) {
      layers = getAllOLLayers(layer, filter);
    }
    if (filter(layer)) {
      layers.push(layer);
    }
    return layers;
  });
}

/**
 * Remove layer taking into account the groups
 * @param {*} map , OL Map
 * @param {*} name, Layer name
 */
export function removeOLLayerByName(map, name) {
  map.getLayers().forEach(layer => {
    if (layer instanceof OlLayerGroup) {
      layer.getLayers().forEach((sublayer, j) => {
        if (
          sublayer &&
          sublayer.get("name") !== undefined &&
          sublayer.get("name") === name
        ) {
          layer.getLayers().removeAt(j);
        }
      });
    } else {
      let layerName = layer?.get("name");
      if (layerName !== undefined && layerName === name) {
        map.removeLayer(layer);
      }
    }
  });
}

/**
 * Load a PNG image as a Source of an OpenLayers imageLayer
 *
 * @param {string} pngUrl An url to a PNG image
 * @param {array} pngBounds xmin,ymin,xmax,ymax values in the PNG srid
 * @param {number} pngSrid CRS identifier
 * @param {object} imageLayer OpenLayers ImageLayer object
 */
export function loadPNGImageSource(pngUrl, pngBounds, pngSrid, imageLayer) {
  if (pngUrl.indexOf("?") === -1) {
    pngUrl = pngUrl + "?" + new Date().getTime();
  }

  const pngDataProj = "EPSG:" + pngSrid; // WGS84 coordinates at tilemapreimageLayer.xml

  let pngExtentTransformed = transformExtent(pngBounds, pngDataProj, EPSG_3857);

  imageLayer.setSource(
    new OlSourceImageStatic({
      url: pngUrl,
      crossOrigin: "anonymous",
      imageExtent: pngBounds,
      projection: "EPSG:" + pngSrid
    })
  );
  imageLayer.setExtent(pngExtentTransformed);
}

/**
 * Load Tile Maps Service OpenLayers
 * @param {*} tmsUrl URL with the root XML file of a tms tileset
 * @param {*} source OpenLayer source layer to set with the TMS
 * @param {*} paletteName Pathfinder Palette to load (or null)
 * @param {*} color Color to load with a linear gradient (if paletteName null)
 */
export function loadTMSColored(
  tmsUrl,
  source,
  paletteName = null,
  color = null,
  layerId = null
) {
  if (source.getSource()) {
    source.getSource().dispose();
    source.setSource(null);
  }
  getTileProps(tmsUrl).then(res => {
    const defaultColor = "rgba(" + theme.palette.secondary.mainRgb + ", 0.6)";
    const tmsBbox = res.BoundingBox;
    const tilesExtent = boundingExtent([
      [tmsBbox.minx, tmsBbox.miny],
      [tmsBbox.maxx, tmsBbox.maxy]
    ]);

    let tilesExtentTransformed = transformExtent(
      tilesExtent,
      EPSG_4326,
      EPSG_3857
    );

    source.setExtent(tilesExtentTransformed);

    let datetime = "";
    datetime = "?" + new Date().getTime();

    const minZoom = res.minZoom;
    const maxZoom = res.maxZoom;

    const tileGrid = createXYZ({
      minZoom: minZoom,
      tileSize: [res.width, res.height],
      maxZoom: maxZoom
    });

    let xyz_source = new XYZ({
      crossOrigin: "anonymous",
      tilePixelRatio: 2,
      projection: EPSG_3857,
      minZoom: minZoom,
      maxZoom: maxZoom,
      url: res.url + "/{z}/{x}/{-y}.png" + datetime
    });
    if (paletteName) {
      getPalette(paletteName)
        .then(palette => {
          let rasterSource = CustomRasterSource(xyz_source);
          source.setSource(null);
          source.setSource(rasterSource);
          if (rasterSource !== null) {
            // Set the values required inside the raster source to be colored `palette`
            rasterSource.set("palette", palette);
            rasterSource.changed();
            rasterSource.on("beforeoperations", event => {
              event.data.palette = rasterSource.get("palette");
            });
          }
        })
        .catch(e => console.log(e));
    } else {
      getRasterLayerRanges(layerId).then(range => {
        let rasterSource = CustomRasterSource(xyz_source);
        source.setSource(null);
        source.setSource(rasterSource);

        if (rasterSource !== null) {
          // Set the values required inside the raster source to be colored `rasterColor`
          rasterSource.set("rasterColor", hexToRgba(color || defaultColor));

          // Set the value `flat` if the raster has no height
          if (range.max - range.min <= 1) {
            rasterSource.set("flat", true);
          }

          rasterSource.changed();
          rasterSource.on("beforeoperations", event => {
            event.data.rasterColor = rasterSource.get("rasterColor");
            event.data.flat = rasterSource.get("flat");
          });
        }
      });
    }
  });
}

/**
 * Tile to longitude
 * @param {*} x
 * @param {*} z
 * @returns
 */
export function tile2long(x, z) {
  return (x / Math.pow(2, z)) * 360 - 180;
}

/**
 * Tile to latitude
 * @param {*} y
 * @param {*} z
 * @returns
 */
export function tile2lat(y, z) {
  var n = Math.PI - (2 * Math.PI * y) / Math.pow(2, z);
  return (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)));
}

/**
 * Function to clear all the layers in the view before change between projects, except basemap
 * @param {*} map
 */
export function clearMapLayers(map) {
  const layers = map.getLayers().getArray();
  // In OpenLayers we have the agreement that the 1st layer is the BaseMap and we want
  // to keep it visible
  layers.slice(1).forEach(layer => {
    if (layer instanceof OlLayerGroup) {
      layer.getLayers().clear(true);
    } else {
      layer.getSource().clear(true);
    }
  });
}

/**
 * Method to remove the resistance map and corridor layers
 * from the map for a given scenario (id).
 *
 * The method finds the layer groups for corridors and resistance
 * maps and then iterates its layers to find the one with
 * the poperty "scenarioId" matching the given id in the parameters.
 *
 * Then use the dispose() method to remove the layers.
 *
 * @param {object} map
 * @param {number} scenarioId
 */
export function clearOLScenarioResultsLayers(map, scenarioId) {
  // Get all the layer groups of the map.
  const layers = map.getLayers().getArray();

  let toRemove = [];

  // Iterate all the layers gropus to find resistance maps
  // and corridors.
  layers.forEach(layer => {
    if (
      layer.get("name") === "resistanceGroup" ||
      layer.get("name") === "corridorGroup"
    ) {
      if (layer instanceof OlLayerGroup) {
        // Get the layers of the resistance map or corridor group.
        const resultsLayer = layer.getLayers().getArray();

        // Filter the layers by scenario id.
        const scenarioLayers = resultsLayer.filter(
          imageLayer =>
            String(imageLayer.get("scenarioId")) === String(scenarioId)
        );

        // Add the matching layers to the remove array.
        toRemove = [...toRemove, ...scenarioLayers];
      }
    }
  });

  // Iterate the array with the layers to remove and
  // use the dispose() method to remove them from the map.
  toRemove.forEach(imageLayer => {
    imageLayer.dispose();
  });
}

/**
 * Change our first OL layer (considered the BaseMap) according to a
 * basemapDefition.
 *
 * BasemapDefinitions can have multiple layers so what this function does is
 * group all the possible generated layers inside an OlLayerGroup and shoeve
 * that group to the 1st position of the OL layers array.
 *
 * @param {ol/Map~Map} map openLayers map to modify
 * @param {obj} basemapDef a definiton of a Pathfinder Basemap
 *
 * WARNING:
 *   Will update the first layer in the OL Layer list (overriding whatever was
 *   there before)
 */
export function changeOLBasemap(map, basemapDef) {
  // Start by the single item layer Definitions (that is Layers that need to be
  // generated by adding a new kind of Layer to an OpenLayers layer group
  var singleItemLayers = basemapDef.layers.filter(function (lDef) {
    return lDef.type !== "MAPBOX";
  });

  let mapLayer = new OlLayerGroup({
    layers: genOLLayersFromBaseMapLayers(singleItemLayers)
  });

  // As a second instance, we have layer Definitions that are more complex
  // and are handled with 3rd party utils, some of those (namely MapBox)
  // need to be applied directly to the OLlayerGroup item
  var mapBoxItemLayers = basemapDef.layers.filter(function (lDef) {
    return lDef.type === "MAPBOX";
  });

  mapBoxItemLayers.forEach(lDef => {
    applyMapBoxStyle(mapLayer, lDef.url);
  });

  mapLayer.set("name", "BaseLayers");
  map.getLayers().setAt(0, mapLayer);
}

/**
 * Generate an Array of OpenLayers Layers from an array of Layers
 * definitions for a BaseMap
 *
 * @param {Array} layerList An array of Layer objects that might
 * be various definitions of WMS,GoogleMaps, Bing, VectorTiles
 * etc. They should provide a type attribute that lets this function
 * decide how to treat them from an OpenLayers perspective. The legacy
 * map formatter is type=WMS and will expect the same structure as
 * previous pathfinder versions <3.4.3.
 *
 * @return Array of valid OpenLayers Layers that can be assigned to an
 * OlLayerGroup
 */
function genOLLayersFromBaseMapLayers(layerDefinitionArray) {
  return layerDefinitionArray.map(lDef => {
    if (lDef.type === "WMS") {
      return new OlWebGLTile({
        name: lDef.name,
        preload: Infinity,
        source: get2DProvider(lDef)
      });
    } else if (lDef.type === "MAPBOX") {
      // The MAPBOX type is handled at a higher level
      // because we're using ol-mapbox-style and that
      // library works with a reference to the OLLayerGroup
      // Instead of returning a list of layers
    }
  });
}

/**
 * Change openlayer div background color
 * @param {*} map
 * @param {*} color
 */
export function changeOLBackgroundColor(map, color = "#FFFFFF") {
  let element = map.getTargetElement();
  element.style.backgroundColor = color;
}

/**
 * Returns a string representing the path to append to a server url that uses
 * the Inverted Y path mode
 * @param {TileCoord} coordinate
 */
function tileUrlFunctionInvYPath(coordinate) {
  const [z, x, y] = coordinate;
  const invertedY = (1 << z) - y - 1;

  // Construct the URL based on the XYZ tile scheme
  return "/" + z + "/" + x + "/" + invertedY + ".jpg";
}

/**
 * Return a reference to a valid tileUrlFunction to handle custom tile
 * grid sources.
 * @param {string} functionName
 */
function getTileUrlFunction(functionName) {
  if (functionName === "tileUrlFunctionInvY") {
    return tileUrlFunctionInvYPath;
  }
  return null;
}

/**
 * change Openlayers BaseMap
 * @return  imageryProvider
 */
export function get2DProvider(background) {
  let imageryProvider;
  switch (background.provider) {
    case "Bing maps":
      let imagerySet = "Aerial";
      if (background.extra_options.imagerySet) {
        if ("imagerySet" in background.extra_options) {
          imagerySet = background.extra_options.imagerySet;
        }
      }
      imageryProvider = new BingMaps({
        key: background.apikey,
        imagerySet: imagerySet
      });
      break;
    case "empty":
      imageryProvider = null;
      break;
    case "Google maps":
    case "Local":
    case "XYZ":
      if (background.extra_options.tileUrlFunction) {
        var customTileUrlFunc = getTileUrlFunction(
          background.extra_options.tileUrlFunction
        );
        // Function defined URL
        imageryProvider = new XYZ({
          tileUrlFunction: function (coordinate) {
            return background.url + customTileUrlFunc(coordinate);
          },
          crossOrigin: "anonymous"
        });
      } else {
        // Default defined URL
        imageryProvider = new XYZ({
          url: background.url,
          crossOrigin: "anonymous"
        });
      }
      break;
    case "URL":
      imageryProvider = new TileWMS({
        url: background.url,
        params: { LAYERS: background.extra_options.layer }
      });
      break;
    case "WMTS":
      if (!background.extra_options.layer) {
        console.log("layer parameter is missing in extra options");
        break;
      }
      if (!background.extra_options.style) {
        console.log(
          "style parameter is missing in extra options. In Geonode, it may be the layer name"
        );
        break;
      }

      const mapMaxZoom = 19;
      let projection = getProjection(EPSG_3857);
      let projectionExtent = projection.getExtent();
      let size = olExtent.getWidth(projectionExtent) / 256;
      let resolutions = new Array(mapMaxZoom);
      let matrixIds = new Array(mapMaxZoom);
      let z;
      let tileMatrixPrefix = "EPSG:3857:";
      let matrixSet = EPSG_3857;
      let style = "default";
      let tilePixelRatio = 1;

      if (background.extra_options.tileMatrixPrefix)
        tileMatrixPrefix = background.extra_options.tileMatrixPrefix;

      if (background.extra_options.matrixSet)
        matrixSet = background.extra_options.matrixSet;

      if (background.extra_options.style)
        style = background.extra_options.style;

      if (background.extra_options.tilePixelRatio)
        tilePixelRatio = background.extra_options.tilePixelRatio;

      for (z = 0; z < mapMaxZoom; z++) {
        // generate resolutions and matrixIds arrays for this WMTS
        resolutions[z] = size / Math.pow(2, z);
        matrixIds[z] = tileMatrixPrefix + z;
      }

      imageryProvider = new WMTS({
        attributions: [""],
        url: background.url,
        layer: background.extra_options.layer,
        matrixSet: matrixSet,
        format: "image/png",
        projection: projection,
        tilePixelRatio: tilePixelRatio,
        tileGrid: new WMTSTileGrid({
          origin: olExtent.getTopLeft(projectionExtent),
          resolutions: resolutions,
          matrixIds: matrixIds,
          tileSize: 256
        }),
        style: style
      });
      break;

    default:
      break;
  }
  return imageryProvider;
}

/**
 * Toggle active interaction value by intanceof
 * @param {*} map
 * @param {*} active
 * @param {*} type
 */
export function toggleActiveInteraction(map, active, type) {
  //set Active type Interaction
  const interactions = map.getInteractions().getArray();

  interactions.forEach(interaction => {
    if (interaction instanceof type) {
      //update the interaction state
      setTimeout(interaction.setActive(active), 500);
      return;
    }
  });
}

export function getAreaExtent(mapObject) {
  return mapObject
    .getLayers()
    .getArray()
    .filter(item => item.get("name") === "projectArea")[0]
    .getSource()
    .getExtent();
}

export function wgs84ToUTM(lon_deg, lat_deg, zone = -1) {
  if (zone == -1) zone = parseInt(((lon_deg + 180) / 6) % 60) + 1;

  // define proj4_defs for easy uses
  // "EPSG:4326" for long/lat degrees, no projection
  // "EPSG:AUTO" for UTM 'auto zone' projection
  proj4.defs([
    [
      EPSG_4326,
      "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees"
    ],
    ["EPSG:UTM", "+proj=utm +zone=" + zone + " +datum=WGS84 +units=m +no_defs"]
  ]);
  register(proj4);
  // usage:
  // conversion from (long/lat) to UTM (E/N)
  let point = fromLonLat([lon_deg, lat_deg], "EPSG:UTM");
  return [zone, point];
}

// A bounce easing method (from https://github.com/DmitryBaranovskiy/raphael).
function bounce(t) {
  const s = 7.5625;
  const p = 2.75;
  let l;
  if (t < 1 / p) {
    l = s * t * t;
  } else {
    if (t < 2 / p) {
      t -= 1.5 / p;
      l = s * t * t + 0.75;
    } else {
      if (t < 2.5 / p) {
        t -= 2.25 / p;
        l = s * t * t + 0.9375;
      } else {
        t -= 2.625 / p;
        l = s * t * t + 0.984375;
      }
    }
  }
  return l;
}

/**
 * Method to bounce from the current point in the given map to the given
 * lon and lat.
 *
 * @param {object} map // Target map where the circle will be drawn.
 * @param {number} lon // Longitude for the center of the circle.
 * @param {number} lat // Lattitude for the center of the circle.
 */
export function olBounceTo(map, lon, lat) {
  let view = map.getView();
  const point = reprojectPointAsCoords(new Point([lon, lat]));

  view.animate({
    center: point,
    duration: 2000,
    easing: bounce,
    zoom: 18
  });
}

/**
 * Method to fly from the current point in the given map to the given
 * lon and lat.
 *
 * @param {object} map // Target map where the circle will be drawn.
 * @param {number} lon // Longitude for the center of the circle.
 * @param {number} lat // Lattitude for the center of the circle.
 */
export function olFlyTo(map, lon, lat) {
  let view = map.getView();
  const point = reprojectPointAsCoords(new Point([lon, lat]));
  const duration = 1000;
  const zoom = view.getZoom();
  let parts = 2;
  let called = false;

  function callback(complete) {
    --parts;
    if (called) {
      return;
    }
    if (parts === 0 || !complete) {
      called = true;
    }
  }

  view.animate(
    {
      center: point,
      duration: duration
    },
    callback
  );

  view.animate(
    {
      zoom: zoom - 1,
      duration: duration / 2
    },
    {
      zoom: 16,
      duration: duration / 2
    },
    callback
  );
}

/**
 * Method that returns a circle feature with the center in the
 * given lon and lat and with the given radius in meters.
 *
 * @param {object} map // Target map where the circle will be drawn.
 * @param {number} lon // Longitude for the center of the circle.
 * @param {number} lat // Lattitude for the center of the circle.
 * @param {number} radius  // Radius of the circle.
 * @returns object // OL Feature
 */
export function olCircleInMeters(map, lon, lat, radius) {
  const point = reprojectPointAsCoords(new Point([lon, lat]));
  const view = map.getView();
  const projection = view.getProjection();
  const resolutionAtEquator = view.getResolution();

  const pointResolution = getPointResolution(
    projection,
    resolutionAtEquator,
    point
  );
  const resolutionFactor = resolutionAtEquator / pointResolution;
  let realRadius = (radius / METERS_PER_UNIT.m) * resolutionFactor;

  let circle = new Circle(point, realRadius);
  return new Feature(circle);
}

export function olAddLayerToCollection(map, collectionName, newLayer) {
  try {
    map.getLayers().forEach(layer => {
      if (layer.get("name") === collectionName) {
        if (layer instanceof OLayerCollection) {
          let collection = layer;
          collection.push(newLayer);
        }
      }
    });
  } catch (err) {
    console.log(err);
  }
}

/**
 * Method that adds a layer into a layer group. It receives the map object,
 * the name of the group and the layer to add.
 *
 * @param {object} map The map object where the layer will be added.
 * @param {string} layerGroupName The layer group name.
 * @param {object} newLayer The layer to add.
 */
export function olAddLayerToGroup(map, layerGroupName, newLayer) {
  try {
    // Iterate map layers.
    map.getLayers().forEach(layer => {
      if (layer.get("name") === layerGroupName) {
        if (layer instanceof OlLayerGroup) {
          // Get the layers of the layer group.
          let innerLayers = layer.getLayers();
          // Add the new layer.
          innerLayers.push(newLayer);
        }
      }
    });
  } catch (err) {
    Sentry.captureException(err);
  }
}

/**
 * Method to remove a layer from a layer group using
 * the `id` custom property.
 *
 * @param {object} map The map object where the layer will be added.
 * @param {string} layerGroupName The layer group name.
 * @param {number} id Custom poperty with the `id` of the layer.
 */
export function olRemoveLayerFromGroup(map, layerGroupName, id) {
  try {
    // Iterate map layers.
    map.getLayers().forEach(layer => {
      if (layer.get("name") === layerGroupName) {
        if (layer instanceof OlLayerGroup) {
          // Get the layers of the layer group.
          let innerLayers = layer.getLayers();

          // Iterate the layers in the group to find
          // the layer id to remove.
          innerLayers.forEach(innerLayer => {
            // Look for the `id` property matching the `id` from params-
            if (Number(innerLayer.get("id")) === Number(id)) {
              // If found, dispose the layer.
              innerLayer.dispose();
            }
          });
        }
      }
    });
  } catch (err) {
    Sentry.captureException(err);
  }
}

export function olGetLayerInGroupById(map, layerGroupName, id) {
  try {
    let foundItem = null;

    // Iterate map layers.
    map.getLayers().forEach(layer => {
      if (layer.get("name") === layerGroupName) {
        if (layer instanceof OlLayerGroup) {
          // Get the layers of the layer group.
          let innerLayers = layer.getLayers();

          // Iterate the layers in the group to find
          // the layer id to remove.
          innerLayers.forEach(innerLayer => {
            // Look for the `id` property matching the `id` from params-
            if (Number(innerLayer.get("id")) === Number(id)) {
              // If found, dispose the layer.
              foundItem = innerLayer;
            }
          });
        }
      }
    });
    return foundItem;
  } catch (err) {
    Sentry.captureException(err);
  }
}

window.getAreaExtent = getAreaExtent;
