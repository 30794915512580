import React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";

/**
 * Custom component to be used for all dialog titles.
 * The component acts as a wrapper of the material-ui DialogTitle component,
 * ensuring that all dialog titles have consistent styling across the app.
 *
 * To use it, simply render it nested inside a SmallCustomDialog or
 * LargeCustomDialog component. Any additional props on this component
 * will be passed on to the wrapped DialogTitle component.
 */
function CustomDialogTitle(props) {
  // props
  const { children, typographyStyle, id, ...other } = props;

  // styles
  const classes = useStyles();

  return (
    <DialogTitle disableTypography={true} {...other}>
      <Typography
        className={classes.pointTitle}
        variant="h1"
        style={typographyStyle}
      >
        {children}
      </Typography>
    </DialogTitle>
  );
}

CustomDialogTitle.propTypes = {
  /** Component children */
  children: PropTypes.any,
  /** h2 title variation */
  variant: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string
};

export default CustomDialogTitle;
