import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";

// Features
import { CookieSettingsDialog } from "features/Cookies/CookieSettingsDialog";

// Utils
import {
  addAnalyticsScripts,
  getCookieConsent,
  removeAnalyticsScripts,
  setCookieConsent
} from "utils/CookieTools";

// Assets
import { CookieState } from "assets/global";

const StateContext = React.createContext();
const DispatchContext = React.createContext();

/**
 * Initial state for the Cookies Context
 */
const initialState = {
  cookieConsentChoice: CookieState.UNKNOWN,
  cookieSettingsDialogOpen: false,
  error: null
};

/**
 * Reducer with the actions to update the Cookies Context state.
 * @param {object} state
 * @param {object} action
 * @returns {object} The new state
 */
function CookiesReducer(state, action) {
  switch (action.type) {
    case "SET_COOKIE_CONSENT": {
      return {
        ...state,
        cookieConsentChoice: action.payload.consent,
        error: null
      };
    }
    case "SET_DIALOG_OPEN": {
      return {
        ...state,
        cookieSettingsDialogOpen: action.payload.isOpen,
        error: null
      };
    }
    case "SET_ERROR": {
      return {
        ...state,
        error: action.payload.error
      };
    }
    default: {
      return state;
    }
  }
}

/**
 * Provides all children with the state and the dispatch of the
 * Cookies Context.
 *
 * @param {object} props - Component props
 * @param {React.ReactNode} props.children - Child components
 * @returns {React.ReactElement} The provider component
 */
export function CookiesProvider({ children }) {
  const [state, dispatch] = React.useReducer(CookiesReducer, initialState);

  // Side-effect for loading the cookie consent state from the local storage
  useEffect(() => {
    const savedConsent = getCookieConsent();
    dispatch({
      type: "SET_COOKIE_CONSENT",
      payload: { consent: savedConsent }
    });
  }, []);

  // Side-effect handling the logic for adding and removing the analytics scripts
  useEffect(() => {
    if (
      state.cookieConsentChoice === CookieState.NECESSARY_ONLY ||
      state.cookieConsentChoice === CookieState.UNKNOWN
    ) {
      removeAnalyticsScripts();
      return;
    }

    addAnalyticsScripts();
  }, [state.cookieConsentChoice]);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>
        {children}
        <CookieSettingsDialog
          open={state.cookieSettingsDialogOpen}
          onClose={() => {
            dispatch({
              type: "SET_DIALOG_OPEN",
              payload: { isOpen: false }
            });
          }}
        />
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
}

CookiesProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};

/**
 * Hook to access and manage the state of the Cookies Context
 * @returns {object} The cookies context state and actions
 */
export function useCookies() {
  const cookiesState = useContext(StateContext);
  const cookiesDispatch = useContext(DispatchContext);

  function updateCookieConsent(consent) {
    // Save it in the local storage
    setCookieConsent(consent);
    // And in the state
    cookiesDispatch({
      type: "SET_COOKIE_CONSENT",
      payload: { consent }
    });
  }

  return {
    cookieConsentChoice: cookiesState.cookieConsentChoice,
    isOpenCookieSettingsDialog: cookiesState.cookieSettingsDialogOpen,
    openCookieSettingsDialog: () =>
      cookiesDispatch({
        type: "SET_DIALOG_OPEN",
        payload: { isOpen: true }
      }),
    acceptCookieConsent: () => updateCookieConsent(CookieState.ACCEPTED_ALL),
    declineCookieConsent: () => updateCookieConsent(CookieState.NECESSARY_ONLY),
    error: cookiesState.error
  };
}
