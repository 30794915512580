// Third-party
import * as Sentry from "@sentry/browser";

// Cesium
import {
  Cartesian3,
  HeightReference,
  GeoJsonDataSource,
  PointGraphics,
  Rectangle
} from "cesium/Build/Cesium/Cesium";

// Utils
import GilyticsImageryProvider from "utils/Cesium/GilyticsImageryProvider";
import { getDatasourceLayerByName } from "utils/Cesium/CesiumDatasourceUtils";
import {
  createIntermediateEntity,
  getColorByString
} from "utils/Cesium/CesiumUtils";
import { rgbaFromString } from "utils/ColorUtils";

// Other
import {
  intermediatePointName,
  corridorLayerName,
  resistanceLayerName
} from "assets/global";

/**
 * Get Imagery Layer By Name.
 *
 * @param {object} viewer Cesium viewer instance.
 * @param {string} name Name of the layer.
 *
 * @returns {object} Cesium image layer.
 */
export function getImageryLayersByName(viewer, name) {
  let i;
  let l = viewer.imageryLayers.length;
  for (i = 0; i < l; i++) {
    let layer = viewer.imageryLayers._layers[i];
    if (layer.name === name) {
      return layer;
    }
  }
}

/**
 * Show cesium layer, left menu.
 *
 * @param {object} viewer Cesium viewer instance.
 * @param {string} layerName Name of the layer to show.
 * @param {object} data Layer data.
 * @param {boolean} isBuffer Flag to mark a buffered layer.
 *
 * @returns
 */
// The polygons outlines are not seen if the terrain is activated.
// Maybe create a line using turf and add a separated lines
// Support for polygon outlines on terrain : https://github.com/AnalyticalGraphicsInc/cesium/issues/6694
export function showLayer(viewer, layerName, data, isBuffer = false) {
  let isAdded = findDatasourceLayerByName(viewer, layerName);
  if (isAdded) {
    return;
  }
  let defaultLayerColor = getColorByString("#4a90e2", 0.8);
  //layerColor is rgba format
  let layerColor =
    data.config.layer_color !== ""
      ? data.config.layer_color
      : defaultLayerColor;
  // Layer data
  const processedData = data.processed_data;
  let layerData = processedData;

  // Original layer color
  const originalColor = getColorByString(layerColor);
  let shades = [];

  // Add necessary value if layer is buffer
  if (isBuffer) {
    // Get layer shades
    shades = getColorShades(layerColor, processedData.geometries.length);
    // Add index value
    let features = [];
    processedData.geometries.reverse().map((item, index) => {
      let feature = {
        type: "Feature",
        geometry: item,
        properties: {
          index: index,
          geometriesLength: processedData.geometries.length
        }
      };
      features.push(feature);
    });
    layerData = {
      type: "FeatureCollection",
      features: features
    };
  }

  // Create DataSource
  new GeoJsonDataSource(layerName)
    .load(layerData, {
      clampToGround: true,
      fill: originalColor
    })
    .then(dataSource => {
      let entities = dataSource.entities.values;

      for (const entity of entities) {
        // Change points style
        if (entity.billboard) {
          entity.billboard = undefined;
          entity.point = new PointGraphics({
            color: originalColor,
            pixelSize: 10,
            outlineColor: getColorByString("#ffffff"),
            outlineWidth: 2,
            heightReference: HeightReference.CLAMP_TO_GROUND,
            disableDepthTestDistance: Number.POSITIVE_INFINITY
          });
        }
        // Change Lines style
        if (entity.polyline) {
          entity.polyline.material.color.setValue(originalColor);
        }
        // Change entity color by index if is buffer
        if (isBuffer && shades.length > 0) {
          let findex = entity.properties.index.getValue();
          entity.polygon.material = styleByEntityIndex(shades, findex);
        }
      }
      // Add datasource and request
      viewer.dataSources.add(dataSource);
      viewer.scene.requestRender();
    });
}

/**
 * Returns a specific color shade or a default one.
 *
 * @param {array} shades A set of shade values.
 * @param {number} ftidx Index to search color.
 *
 * @returns {string} Color shade.
 */
export function styleByEntityIndex(shades, ftidx) {
  if (shades.length < ftidx) {
    return getColorByString("#4a90e2", 0.8);
  }
  return getColorByString(shades[ftidx]);
}

/**
 * Get layer shades used in buffer layers.
 *
 * @param {string} color
 * @param {number} classes
 *
 * @returns {array}
 */
export function getColorShades(color, classes = 0) {
  let shades = [...Array(classes).keys()].map(x => x + 1).reverse();
  let c = rgbaFromString(color);
  shades = shades.map(
    x =>
      `rgba(${parseInt((x * c[0]) / classes)}, ` +
      `${parseInt((x * c[1]) / classes)}, ` +
      `${parseInt((x * c[2]) / classes)}, ` +
      `${0.8 * c[3]})`
  );
  return shades;
}

/**
 *
 * Remove cesium project layers (Layers from left menu).
 *
 * @param {object} viewer Viewer to search on.
 *
 */
export function removeCesiumLayersProjects(viewer) {
  let i;
  for (i = 0; i < viewer.dataSources.length; i++) {
    let d = viewer.dataSources.get(i);
    if (d.name.includes("_buffered") || d.name.includes("_original")) {
      viewer.dataSources.remove(d);
      i--;
    }
  }
  viewer.scene.requestRender();
}

/**
 * Check if datasource exists.
 *
 * @param {object} viewer Viewer to search on.
 * @param {string} searchName Name to match.
 *
 * @returns {boolean}
 */
export function findDatasourceLayerByName(viewer, searchName) {
  if (viewer.dataSources.getByName(searchName).length > 0) {
    return true;
  }
  return false;
}

/**
 * Check if imagery layer exists.
 *
 * @param {object} viewer Viewer to search on.
 * @param {string} searchName Name to match.
 *
 * @returns {boolean}
 */
export function findImageryLayerByName(viewer, searchName) {
  let i;
  let l = viewer.imageryLayers.length;
  for (i = 0; i < l; i++) {
    if (viewer.imageryLayers._layers[i].name === searchName) {
      return true;
    }
  }
  return false;
}

/**
 * Removes all imageryLayers that have the property name with the
 * provided value.
 *
 * @param {object} viewer Viewer to search on.
 * @param {string} name Name to match.
 *
 */
export function removeCesiumImageryLayerByName(viewer, name) {
  let iLayers = viewer.imageryLayers;
  let i;
  for (i = 0; i < iLayers.length; i++) {
    try {
      if (iLayers._layers[i].name.indexOf(name) >= 0) {
        iLayers.remove(iLayers._layers[i]);
        i--;
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }
  viewer.scene.requestRender();
}
/**
 * Remove cesium layer.
 *
 * @param {object} viewer Cesium viewer.
 * @param {string} name Name of the layer.
 *
 */
export function removeCesiumLayerByName(viewer, name) {
  let dataSource = getDatasourceLayerByName(viewer, name);
  viewer.dataSources.remove(dataSource);
  viewer.scene.requestRender();
}

/**
 * Find cesium layer returns true if found.
 *
 * @param {object} viewer Cesium viewer.
 * @param {string} layerName Name of the layer.
 *
 */
export function findLayer(viewer, layerName) {
  let is_layer_in_imagery = findImageryLayerByName(viewer, layerName);
  let is_layer_in_vectorlayers = findDatasourceLayerByName(viewer, layerName);
  return is_layer_in_imagery || is_layer_in_vectorlayers;
}

/**
 * Add Colorize Image Layer
 *
 * @param {object} viewer Cesium viewer.
 * @param {string} name Name of the layer.
 * @param {objext} data Data of the layer.
 * @param {string} color Color of the layer.
 *
 */
export function addColoredImageryLayer(viewer, name, data, color = null) {
  // Default Color
  let red = 255;
  let blue = 255;
  let green = 255;
  let alpha = 255;
  // Check if has input color
  if (color) {
    let c = rgbaFromString(color);
    red = c[0];
    green = c[1];
    blue = c[2];
    alpha = c[3];
  }

  // Add Gilytics Color Imagery
  let layer = new GilyticsImageryProvider({
    rectangle: Rectangle.fromDegrees(...data.extent),
    url: data.image,
    red: red,
    green: green,
    blue: blue,
    alpha: alpha
  });

  let imageryLayers = viewer.imageryLayers;

  let imgLayer = imageryLayers.addImageryProvider(layer, imageryLayers.length);
  imgLayer.alpha = data.alpha;
  imgLayer.name = name + data.id;
}

/**
 * Load Intermediate Points.
 *
 * @param {object} viewer Cesium viewer.
 * @param {array} intermediatePoints Points to show.
 *
 */
export const loadIntermediatePoints = (viewer, intermediatePoints) => {
  if (viewer) {
    let IntermediatePointsSource = getDatasourceLayerByName(
      viewer,
      intermediatePointName
    );
    if (IntermediatePointsSource) {
      let entities = IntermediatePointsSource.entities;
      entities.suspendEvents();
      // Remove all entities
      entities.removeAll();

      if (intermediatePoints) {
        let coord = intermediatePoints.coordinates;
        let i;
        for (i = 0; i < coord.length; i++) {
          let point = Cartesian3.fromDegrees(...coord[i], 0);
          entities.add(createIntermediateEntity(point, String(i + 1)));
        }
      }
      entities.resumeEvents();
      viewer.scene.requestRender();
    }
  }
};

/**
 * Method to remove the resistance map and corridor layers
 * from the cesium globe for a given scenario (id).
 *
 * The method itrates the imagery layers in the viewer to
 * find the ones matching with the resistanceLayerName +
 * the scenarioId or the corridorLayerName + the scenarioId.
 *
 * Finally it iterates the array with all the matching layers
 * to use the remove() method to remove them from the viewer.
 *
 * @param {object} viewer Cesium viewer.
 * @param {number} scenarioId The id of the scenario.
 *
 */
export const clearCesiumScenarioResultsLayers = (viewer, scenarioId) => {
  const toRemove = [];

  // Iterate the imagery layers to find the corresponding
  // to the resistance map or corridor of the given scenario (id).
  for (let i = 0; i < viewer.imageryLayers.length; i++) {
    if (
      viewer.imageryLayers._layers[i].name.includes(
        resistanceLayerName + scenarioId
      ) ||
      viewer.imageryLayers._layers[i].name.includes(
        corridorLayerName + scenarioId
      )
    ) {
      // If the layer name matches we push the layer into
      // the array of layers to remove.
      toRemove.push(viewer.imageryLayers._layers[i]);
    }

    // Finally we remove the layers from the viewer.
    for (const element of toRemove) {
      viewer.imageryLayers.remove(element);
    }

    viewer.scene.requestRender();
  }
};

/**
 * Change the color of a layer by its name.
 *
 * @param {object} viewer Cesium viewer.
 * @param {string} name Name of the layer.
 * @param {string} color New color.
 *
 */
export function changeLayerColorByName(viewer, name, color, isBuffer = false) {
  let dataSource = getDatasourceLayerByName(viewer, name);
  if (dataSource) {
    let entities = dataSource.entities;
    entities.suspendEvents();
    let values = entities.values;
    let layerColor = getColorByString(color);

    let polygonMat = layerColor;
    let shades = [];
    if (isBuffer) {
      let geometriesLength = values[0].properties.geometriesLength.getValue();
      shades = getColorShades(color, geometriesLength);
    }
    // Change entity color
    for (const entity of values) {
      // Check if have shades
      if (isBuffer && shades.length > 0) {
        let findex = entity.properties.index.getValue();
        polygonMat = styleByEntityIndex(shades, findex);
      }
      if (entity.polygon) entity.polygon.material = polygonMat;
      if (entity.point) entity.point.color = layerColor;
      if (entity.polyline) entity.polyline.material.color = layerColor;
    }
    // Resume and request
    entities.resumeEvents();
    viewer.scene.requestRender();
  }
}
