import React, { useState } from "react";
import PropTypes from "prop-types";

// Material UI
import Typography from "@material-ui/core/Typography";

// i18n
import { useTranslation } from "react-i18next";

/**
 * A component that displays a text with a "Read More" / "Show Less" functionality.
 *
 * @param {Object} props - The component props.
 * @param {string|string[]} props.text - The text to display. Can be a string or an array of strings.
 * @param {number} [props.amountOfWords=36] - The number of words to display before truncating.
 * @param {Object} props - Optional Typography component props.
 *
 * @returns {React.ReactElement} The rendered component.
 *
 * @example
 * <ReadMoreTextWrapper text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
 *
 * @example
 * <ReadMoreTextWrapper text={["Lorem ipsum dolor sit amet, consectetur adipiscing elit.", "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."]} />
 */
export function ReadMoreTextWrapper({ text, amountOfWords = 36, ...props }) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const combinedText = Array.isArray(text) ? text.join("\n\n") : text;
  const splittedText = combinedText.split(" ");
  const itCanOverflow = splittedText.length > amountOfWords;
  const beginText = itCanOverflow
    ? splittedText.slice(0, amountOfWords - 1).join(" ")
    : combinedText;
  const endText = splittedText.slice(amountOfWords - 1).join(" ");

  const handleKeyboard = e => {
    if (e.code === "Space" || e.code === "Enter") {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <Typography {...props}>
      {beginText}
      {itCanOverflow && (
        <>
          {!isExpanded && <span>…</span>}
          <span
            aria-hidden={!isExpanded}
            style={{
              display: isExpanded ? "inline" : "none",
              whiteSpace: "pre-wrap"
            }}
          >
            {" "}
            {endText}
          </span>{" "}
          <span
            role="button"
            tabIndex={0}
            aria-expanded={isExpanded}
            // eslint-disable-next-line react/prop-types
            aria-controls={props?.id}
            onKeyDown={handleKeyboard}
            onClick={() => setIsExpanded(!isExpanded)}
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "blue",
              display: "inline"
            }}
          >
            {isExpanded ? t("common.ShowLess") : t("common.ShowMore")}
          </span>
        </>
      )}
    </Typography>
  );
}

ReadMoreTextWrapper.propTypes = {
  text: PropTypes.oneOf(PropTypes.arrayOf(PropTypes.string), PropTypes.string)
    .isRequired,
  amountOfWords: PropTypes.number
};
