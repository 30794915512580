import {
  Math,
  Cartesian3,
  Cartographic,
  CesiumTerrainProvider,
  IonResource,
  sampleTerrainMostDetailed,
  Ellipsoid
} from "cesium/Build/Cesium/Cesium";

/**
 * Cartographic Coordinates
 * Cartographic coordinates represent a position on Earth's surface using
 * longitude, latitude, and height. In Cesium, these are typically expressed
 * in radians for longitude and latitude, with height in meters above the ellipsoid.
 *
 * Geographic Coordinates
 * Geographic coordinates specify a location on Earth's surface using angular
 * measurements of latitude and longitude. They are based on a spherical or ellipsoidal
 * model of the Earth and are commonly expressed in degrees.
 *
 * Cartesian Coordinates
 * Cartesian coordinates define a point in three-dimensional space using x, y, and z values.
 * In Cesium, these represent a position relative to the center of the Earth, with units
 * typically in meters.
 */

/**
 * Convert from degress to radians.
 *
 * @param {number} value
 */
export function degreesToRadians(value) {
  return Math.toRadians(value);
}

/**
 * Convert from radians to degrees.
 *
 * @param {number} value
 */
export function radianToDegrees(value) {
  return Math.toDegrees(value);
}

/**
 * Create the Elevation Profile add elevation of a set of points
 * given a terrain asset.
 *
 * @param {array} coordinates The set of coordinates to calculate the elevation.
 * @param {number} terrainAsset The terrain asset id to sample from.
 */
export async function getCesiumElevatonPoints(coordinates, terrainAsset = 1) {
  let terrainProvider = new CesiumTerrainProvider({
    url: IonResource.fromAssetId(terrainAsset)
  });
  return await sampleTerrainMostDetailed(terrainProvider, coordinates);
}

/**
 * Converts geographic coordinates to cartografic coordinates.
 *
 * @param {array} coordinates Array of geographic coordinates.
 *
 * @returns {array} Array of cartografic coordinates.
 */
export function geographicToCartographic(coordinates) {
  let cartographicPoints = [];

  for (let i = 0; i <= coordinates.length - 1; i++) {
    cartographicPoints.push(
      Cartographic.fromDegrees(...coordinates[i], new Cartographic())
    );
  }
  return cartographicPoints;
}

/**
 * Converts Cartographic coordinates to Cartesian (3D) coordinates.
 *
 * @param {array} coordinates Array of Cartographic coordinates.
 *
 * @returns {array} Array of Cartesian coordinates.
 */
export function cartographicToCartesian3D(coordinates, zOffset = 0) {
  let cartesianPoints = [];
  coordinates.forEach(position => {
    cartesianPoints.push(
      Cartesian3.fromRadians(
        position.longitude,
        position.latitude,
        position.height + zOffset
      )
    );
  });

  return cartesianPoints;
}

/**
 * Converts geographic coordinates to cartographic 3D coordinates.
 *
 * @param {array} coordinates Array of geographic coordinates (x,y).
 *
 * @returns {array} Array of cartographic 3D coordinates.
 */
export async function geographicToCartographic3D(coordinates, terrainAsset) {
  // Convert from geographic to cartographic.
  const cartographicPoints = geographicToCartographic(coordinates);

  // Calculate the elevation points for each point in the path
  // using the terrainAsset.
  const cartographic3DPoints = await getCesiumElevatonPoints(
    cartographicPoints,
    terrainAsset
  );
  return cartographic3DPoints;
}

/**
 * Converts geographic coordinates (2D) to cartesian coordinates 3D.
 *
 * @param {array} coordinates Array of objects with longitude, latitude properties.
 * @param {string} terrainAsset The reference to the terrain asset.
 * @param {number} [zOffset] An offset (positive or negative) for the z axis.
 *
 * @returns {array} Array of cartesian points with x, y and z.
 */
export async function geographicToCartesian3D(
  coordinates,
  terrainAsset,
  zOffset = 0
) {
  const cartographic3DPoints = await geographicToCartographic3D(
    coordinates,
    terrainAsset
  );

  const cartesian3DPoints = cartographicToCartesian3D(
    cartographic3DPoints,
    zOffset
  );

  return cartesian3DPoints;
}

/**
 * Convert degrees to cartografic coordinates.
 *
 * @param {number} longitude
 * @param {number} latitude
 *
 * @returns {array} Cartographic coordinates.
 */
export const cartographicFromDegrees = (longitude, latitude) => {
  return new Cartographic.fromDegrees(longitude, latitude);
};

/**
 * Calculate Cartesian 3D distance from a to b.
 *
 * @param {array} a First point.
 * @param {array} b Second point.
 */
export function computeCartesian3Distance(a, b) {
  return parseFloat(Cartesian3.distance(a, b)).toFixed(2);
}

/**
 * Converts a position from cartographic from cartesian.
 *
 * @param {object} position Coordinates in Cartesian
 * @returns {object} Position in cartographic.
 */
export function cartographicFromCartesian(position) {
  return Cartographic.fromCartesian(position);
}

/**
 * Get cartographic coordinates in the picked ray.
 *
 * @param {object} viewer Cesium viewer instance.
 * @param {array} position Position where to pick the ray.
 *
 * @returns {object} Cartographic position.
 */
export const getCartographic = (viewer, position) => {
  let camera = viewer.camera;
  let scene = viewer.scene;

  let position1;
  let cartographic;
  let ray = camera.getPickRay(position);
  position1 = ray ? scene.globe.pick(ray, viewer.scene) : false;

  cartographic = position1
    ? Ellipsoid.WGS84.cartesianToCartographic(position1)
    : false;
  return cartographic;
};
