// Utils
import {
  addImageryLayer,
  loadTMSWithColor,
  changeImageryLayerOpacityByName,
  removeImageryLayerByName
} from "utils/Cesium/CesiumImageryUtils";

// Services
import { getPalette } from "services/layer";

// Other
import { resistanceLayerName } from "assets/global";

export async function cesiumAddResistanceMap(viewer, resistanceMap, opacity) {
  const palette = await getPalette("RM_YBR");

  let layerName = resistanceLayerName + resistanceMap.id;
  if (resistanceMap.tms_tiles) {
    // Load Tile Map Service
    const payload = {
      layerId: resistanceMap.id,
      viewer: viewer,
      gradient: palette,
      tmsUrl: resistanceMap.tms_tiles,
      name: layerName,
      extent: resistanceMap.png_bounds,
      color: null,
      source: "resistanceMap",
      alpha: opacity
    };
    loadTMSWithColor(payload);
  } else {
    addImageryLayer(viewer, layerName, resistanceMap, opacity);
  }
}

export function cesiumRemoveResistanceMap(viewer, resistanceMapId) {
  removeImageryLayerByName(viewer, resistanceLayerName + resistanceMapId);
}

export function cesiumSetResistanceMapOpacity(viewer, resistanceMap, opacity) {
  changeImageryLayerOpacityByName(
    viewer,
    resistanceLayerName + resistanceMap.id,
    opacity
  );
}
