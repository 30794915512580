import React, { useEffect, useContext, useCallback } from "react";

import {
  getKeyStoreKey,
  createKeyStore,
  patchKeyStoreKey
} from "services/keystore";
import { LOCAL_SETTINGS_KEY_NAME } from "assets/global";

// Google Analytics
import useGoogleAnalytics, { GACategories } from "hooks/useGoogleAnalytics";

const DispatchContext = React.createContext();
const StateContext = React.createContext();

const initialState = {
  localSettings: null,
  isFetching: false,
  isReady: false
};

function LocalSettingsReducer(state, action) {
  switch (action.type) {
    case "LOCAL_SETTINGS_SET": {
      return {
        ...state,
        localSettings: action.payload.localSettings
      };
    }

    case "LOCAL_SETTINGS_CLEAR": {
      return {
        ...state,
        localSettings: null
      };
    }

    case "FETCHING_START": {
      return {
        ...state,
        isFetching: true
      };
    }

    case "FETCHING_END": {
      return {
        ...state,
        isFetching: false
      };
    }

    case "SET_READY": {
      return {
        ...state,
        isReady: true
      };
    }

    default: {
      return state;
    }
  }
}

/**
 * Provides all children with the state and the dispatch of Local Settings Provider
 * @param { node } children
 * @returns node
 */
export default function LocalSettingsProvider({ children }) {
  const [state, dispatch] = React.useReducer(
    LocalSettingsReducer,
    initialState
  );

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
}

/**
 * Hook to access and manage the state of the Local Settings Context
 */
const useLocalSettingsContext = () => {
  const localSettingsState = useContext(StateContext);
  const localSettingsDispatch = useContext(DispatchContext);
  const { trackEvent } = useGoogleAnalytics();

  // PRIVATE METHODS   //////////////////////////

  /**
   * Method that returns a copy of the localSettings currently
   * in the state. The copy has no references to the original
   * object.
   */
  const getLocalSettingsClone = useCallback(() => {
    return JSON.parse(JSON.stringify(localSettingsState?.localSettings));
  }, [localSettingsState?.localSettings]);

  /**
   * Method to load the local settings to the state.
   *
   * It first check if we have settings in the browser (local
   * storage) and in that case, use them. Otherwise, it checks
   * the BE for the settings.
   *
   * In the case that we had no settings either in the
   * browser or in BE, it sets an empty object as settings and
   * creates the key in the state, the browser and BE.
   */
  const loadLocalSettings = useCallback(() => {
    localSettingsDispatch({ type: "FETCHING_START" });

    // First we try to recover settings from the browser.
    const browserSettings = localStorage.getItem(LOCAL_SETTINGS_KEY_NAME);

    if (!browserSettings) {
      // If there're no setting in the browser, we call BE
      // to try to find the settings there.
      getKeyStoreKey(LOCAL_SETTINGS_KEY_NAME)
        .then(res => {
          // If we find settings in BE, we store them into
          // the context and also in the browser.
          localStorage.setItem(
            LOCAL_SETTINGS_KEY_NAME,
            JSON.stringify(res.value)
          );

          localSettingsDispatch({
            type: "LOCAL_SETTINGS_SET",
            payload: { localSettings: res.value }
          });

          localSettingsDispatch({ type: "FETCHING_END" });
          return true;
        })
        .catch(err => {
          // If there're no setting in BE then we create the settings
          // as an empty structure and store them in the context, BE and
          // browser.

          // We should check the error here but because 404 eror is intecepted
          // and filtered returning just the text and
          // from here is not possible to know the status
          // or error code.

          // We store the empty settings in BE.
          createKeyStore(LOCAL_SETTINGS_KEY_NAME, {})
            .then(res => {
              // If success, we store the settings in the browser
              // and in local storage.
              localStorage.setItem(LOCAL_SETTINGS_KEY_NAME, JSON.stringify({}));
              localSettingsDispatch({
                type: "LOCAL_SETTINGS_SET",
                payload: { localSettings: {} }
              });

              localSettingsDispatch({ type: "FETCHING_END" });
            })
            .catch(err => {
              // If there's an error saving to BE we store the
              // setting in the browser and in the state anyway.

              // TODO: Report the error to Sentry.
              localStorage.setItem(LOCAL_SETTINGS_KEY_NAME, JSON.stringify({}));
              localSettingsDispatch({
                type: "LOCAL_SETTINGS_SET",
                payload: { localSettings: {} }
              });

              localSettingsDispatch({ type: "FETCHING_END" });
            });

          return true;
        });
    } else {
      // If there're setting in the browser we load them
      // into the context.
      localSettingsDispatch({
        type: "LOCAL_SETTINGS_SET",
        payload: { localSettings: JSON.parse(browserSettings) }
      });

      localSettingsDispatch({ type: "FETCHING_END" });

      // TODO: Check if there're setting in BE and in case
      // they are newer, update browser data.
      return true;
    }
  }, [localSettingsDispatch]);

  // PUBLIC METHODS   //////////////////////////

  /**
   * Method that pathces the local setting in BE
   * with the current local settings in the state.
   *
   * @returns promise
   */
  const uploadLocalSettings = () => {
    // We store the empty settings in BE.
    return patchKeyStoreKey(LOCAL_SETTINGS_KEY_NAME, {
      key: LOCAL_SETTINGS_KEY_NAME,
      value: localSettingsState.localSettings
    });
  };

  /**
   * Method to toggle the Tabbed Results value
   * in the state.
   */
  const toggleTabbedResults = () => {
    let tmpLocalSettings = getLocalSettingsClone();

    tmpLocalSettings.tabbedResults = !tmpLocalSettings.tabbedResults;

    localStorage.setItem(
      LOCAL_SETTINGS_KEY_NAME,
      JSON.stringify(tmpLocalSettings)
    );

    localSettingsDispatch({
      type: "LOCAL_SETTINGS_SET",
      payload: { localSettings: tmpLocalSettings }
    });

    trackEvent({
      category: GACategories.View,
      action: "TabbedResults"
    });
  };

  /**
   * Method to change the Base Map selected
   * in the state.
   * @param {*} baseMap
   */
  const setActiveBaseMap = baseMap => {
    let tmpLocalSettings = getLocalSettingsClone();

    if (!tmpLocalSettings) tmpLocalSettings = {};

    tmpLocalSettings.baseMap = baseMap;

    localStorage.setItem(
      LOCAL_SETTINGS_KEY_NAME,
      JSON.stringify(tmpLocalSettings)
    );

    localSettingsDispatch({
      type: "LOCAL_SETTINGS_SET",
      payload: { localSettings: tmpLocalSettings }
    });
  };

  /**
   * Method to toggle the pylons view value
   * in the state.
   */
  const toggleShowPylons = () => {
    let tmpLocalSettings = getLocalSettingsClone();

    tmpLocalSettings.showPylons = !tmpLocalSettings.showPylons;

    localStorage.setItem(
      LOCAL_SETTINGS_KEY_NAME,
      JSON.stringify(tmpLocalSettings)
    );

    localSettingsDispatch({
      type: "LOCAL_SETTINGS_SET",
      payload: { localSettings: tmpLocalSettings }
    });
  };

  /**
   * Method to toggle the labels view value in the state.
   */
  const toggleShowLabels = () => {
    let tmpLocalSettings = getLocalSettingsClone();

    tmpLocalSettings.showLabels = !tmpLocalSettings.showLabels;

    localStorage.setItem(
      LOCAL_SETTINGS_KEY_NAME,
      JSON.stringify(tmpLocalSettings)
    );

    localSettingsDispatch({
      type: "LOCAL_SETTINGS_SET",
      payload: { localSettings: tmpLocalSettings }
    });
  };

  /**
   * Method to toggle the labels view value in the state.
   */
  const toggleShowMapRoutingWidth = () => {
    let tmpLocalSettings = getLocalSettingsClone();

    tmpLocalSettings.showMapRoutingWidth =
      !tmpLocalSettings.showMapRoutingWidth;

    localStorage.setItem(
      LOCAL_SETTINGS_KEY_NAME,
      JSON.stringify(tmpLocalSettings)
    );

    localSettingsDispatch({
      type: "LOCAL_SETTINGS_SET",
      payload: { localSettings: tmpLocalSettings }
    });
  };

  /**
   * Method set the last scenario selected in a project
   * in the state.
   * {project_id : scenario_id}
   */
  const setLastScenario = (project, scenario) => {
    let tmpLocalSettings = getLocalSettingsClone();

    tmpLocalSettings.lastScenarioByProject[project] = scenario;

    localStorage.setItem(
      LOCAL_SETTINGS_KEY_NAME,
      JSON.stringify(tmpLocalSettings)
    );

    localSettingsDispatch({
      type: "LOCAL_SETTINGS_SET",
      payload: { localSettings: tmpLocalSettings }
    });
  };

  /**
   * Method to delete the project and its last scenario from
   * in the state.
   */
  const deleteLocalProjectAndScenario = project => {
    let tmpLocalSettings = getLocalSettingsClone();

    delete tmpLocalSettings.lastScenarioByProject[project];

    localStorage.setItem(
      LOCAL_SETTINGS_KEY_NAME,
      JSON.stringify(tmpLocalSettings)
    );

    localSettingsDispatch({
      type: "LOCAL_SETTINGS_SET",
      payload: { localSettings: tmpLocalSettings }
    });
  };

  /**
   * Method to set the visibility of an item in the local storage.
   *
   * @param {string} item Type of item: `resistanceMaps`, `corridors', 'comparisonMaps' or `paths`.
   * @param {number} id Id of the item.
   * @param {boolean} value Visibility value: true or false.
   */
  const setLocalVisibility = (item, id, value) => {
    let tmpLocalSettings = JSON.parse(
      localStorage.getItem("pathfinder-local-settings")
    );

    if (!tmpLocalSettings.map) tmpLocalSettings.map = {};
    if (!tmpLocalSettings.map.visibility) tmpLocalSettings.map.visibility = {};

    if (!tmpLocalSettings.map.visibility[item])
      tmpLocalSettings.map.visibility[item] = [];

    const visible = tmpLocalSettings.map.visibility[item].includes(id);

    if (value === true && !visible) {
      tmpLocalSettings.map.visibility[item].push(id);
    } else if (value === false && visible) {
      tmpLocalSettings.map.visibility[item] = tmpLocalSettings.map.visibility[
        item
      ].filter(n => n !== id);
    }

    localStorage.setItem(
      LOCAL_SETTINGS_KEY_NAME,
      JSON.stringify(tmpLocalSettings)
    );

    localSettingsDispatch({
      type: "LOCAL_SETTINGS_SET",
      payload: { localSettings: tmpLocalSettings }
    });
  };

  const getVisibilitySettings = item => {
    let tmpLocalSettings = getLocalSettingsClone();

    if (!tmpLocalSettings.map) return null;
    if (!tmpLocalSettings.map.visibility) return null;
    if (!tmpLocalSettings.map.visibility[item]) return null;

    return tmpLocalSettings.map.visibility[item];
  };

  /**
   * Method to set the visibility of a set of items in the local storage.
   *
   * @param {string} item Type of item: `resistanceMaps`, `corridors', 'comparisonMaps' or `paths`.
   * @param {array} ids Array of item ids.
   * @param {boolean} value Visibility value: true or false.
   */
  const setLocalVisibilityAll = (item, ids, value) => {
    // Get visibility setting for the item (resistanceMaps, corridors, comparisonMaps or paths)
    const tmpLocalVisibilitySettings = getVisibilitySettings(item);

    // Update the visibility item array with the new ids.
    let newSettings = [];
    if (value === true) {
      // Merging the new ids if visibility is true.
      newSettings = [
        ...new Set([...(tmpLocalVisibilitySettings || []), ...ids])
      ];
    } else {
      // Removing the new ids if visibility is false.
      newSettings = tmpLocalVisibilitySettings
        ? tmpLocalVisibilitySettings.filter(item => !ids.includes(item))
        : [];
    }

    // Get all visibility local settings.
    let tmpLocalSettings = JSON.parse(
      localStorage.getItem("pathfinder-local-settings")
    );
    if (!tmpLocalSettings.map) tmpLocalSettings.map = {};
    if (!tmpLocalSettings.map.visibility) tmpLocalSettings.map.visibility = {};
    if (!tmpLocalSettings.map.visibility[item])
      tmpLocalSettings.map.visibility[item] = [];

    // Add the new visibility settings.
    tmpLocalSettings.map.visibility[item] = newSettings;

    // Store new local settings.
    localStorage.setItem(
      LOCAL_SETTINGS_KEY_NAME,
      JSON.stringify(tmpLocalSettings)
    );

    // Dispatch new local settings.
    localSettingsDispatch({
      type: "LOCAL_SETTINGS_SET",
      payload: { localSettings: tmpLocalSettings }
    });
  };

  /**
   * Method to remove unused items in the visibility local storage.
   *
   * @param {string} item Type of item: `resistanceMaps`, `corridors', 'comparisonMaps' or `paths`.
   * @param {array} ids Array of item ids.
   */
  // const purgeLocalVisibility = (item, ids) => {
  //   // Get visibility setting for the item (resistanceMaps, corridors, comparisonMaps or paths)
  //   const tmpLocalVisibilitySettings = getVisibilitySettings(item);

  //   console.log("Visibility Settings: ", tmpLocalVisibilitySettings);
  //   console.log("ITEMS TO KEEP: ", ids);

  //   let newSettings = [];
  //   if (ids) {
  //     // Filter the ids included in the function parameter.
  //     newSettings = tmpLocalVisibilitySettings.filter(item =>
  //       ids.includes(item)
  //     );
  //   }

  //   console.log("New Settings after Purge: ", newSettings);

  //   // Get all visibility local settings.
  //   let tmpLocalSettings = getLocalSettingsClone();
  //   if (!tmpLocalSettings.map) tmpLocalSettings.map = {};
  //   if (!tmpLocalSettings.map.visibility) tmpLocalSettings.map.visibility = {};
  //   if (!tmpLocalSettings.map.visibility[item])
  //     tmpLocalSettings.map.visibility[item] = [];

  //   // Add the new visibility settings.
  //   tmpLocalSettings.map.visibility[item] = newSettings;

  //   // Store new local settings.
  //   localStorage.setItem(
  //     LOCAL_SETTINGS_KEY_NAME,
  //     JSON.stringify(tmpLocalSettings)
  //   );

  //   // Dispatch new local settings.
  //   localSettingsDispatch({
  //     type: "LOCAL_SETTINGS_SET",
  //     payload: { localSettings: tmpLocalSettings }
  //   });
  // };

  /**
   * Method to set the opacity of an item in the local storage.
   *
   * @param {string} item Type of item: `resistanceMaps`, `corridors', 'comparisonMaps' or `paths`.
   * @param {number} id Id of the item.
   * @param {boolean} value Opacity value: 0 to 1
   */
  const setLocalOpacity = (item, id, value) => {
    let tmpLocalSettings = getLocalSettingsClone();

    if (!tmpLocalSettings.map) tmpLocalSettings.map = {};
    if (!tmpLocalSettings.map.opacity) tmpLocalSettings.map.opacity = {};

    if (!tmpLocalSettings.map.opacity[item])
      tmpLocalSettings.map.opacity[item] = {};

    if (tmpLocalSettings.map.opacity[item].hasOwnProperty(id)) {
      tmpLocalSettings.map.opacity[item][id] = value;
    } else {
      tmpLocalSettings.map.opacity[item] = {
        ...tmpLocalSettings.map.opacity[item],
        [id]: value
      };
    }

    localStorage.setItem(
      LOCAL_SETTINGS_KEY_NAME,
      JSON.stringify(tmpLocalSettings)
    );

    localSettingsDispatch({
      type: "LOCAL_SETTINGS_SET",
      payload: { localSettings: tmpLocalSettings }
    });
  };

  /**
   * Method to set the opacity of an item in the local storage.
   *
   * @param {string} item Type of item: `layer` or `path`.
   * @param {number} id Id of the item.
   * @param {boolean} value Opacity value: 0 to 1
   */
  const setLocalColor = (item, id, value) => {
    let tmpLocalSettings = getLocalSettingsClone();

    if (!tmpLocalSettings.map) tmpLocalSettings.map = {};
    if (!tmpLocalSettings.map.color) tmpLocalSettings.map.color = {};

    if (!tmpLocalSettings.map.color[item])
      tmpLocalSettings.map.color[item] = {};

    if (tmpLocalSettings.map.color[item].hasOwnProperty(id)) {
      tmpLocalSettings.map.color[item][id] = value;
    } else {
      tmpLocalSettings.map.color[item] = {
        ...tmpLocalSettings.map.color[item],
        [id]: value
      };
    }

    localStorage.setItem(
      LOCAL_SETTINGS_KEY_NAME,
      JSON.stringify(tmpLocalSettings)
    );

    localSettingsDispatch({
      type: "LOCAL_SETTINGS_SET",
      payload: { localSettings: tmpLocalSettings }
    });
  };

  const setScenarioSelectorHeight = (project, height) => {
    let tmpLocalSettings = getLocalSettingsClone();

    if (!tmpLocalSettings.scenarioSelectorHeight)
      tmpLocalSettings.scenarioSelectorHeight = {};
    tmpLocalSettings.scenarioSelectorHeight[project] = height;

    localStorage.setItem(
      LOCAL_SETTINGS_KEY_NAME,
      JSON.stringify(tmpLocalSettings)
    );

    localSettingsDispatch({
      type: "LOCAL_SETTINGS_SET",
      payload: { localSettings: tmpLocalSettings }
    });
  };

  const setExportScenarioResults = (
    includeItems,
    pathFormats,
    coordinateReferenceSystem
  ) => {
    let tmpLocalSettings = getLocalSettingsClone();

    tmpLocalSettings.exportScenarioResultsDialog = {
      includeItems,
      pathFormats,
      coordinateReferenceSystem
    };

    localStorage.setItem(
      LOCAL_SETTINGS_KEY_NAME,
      JSON.stringify(tmpLocalSettings)
    );

    localSettingsDispatch({
      type: "LOCAL_SETTINGS_SET",
      payload: { localSettings: tmpLocalSettings }
    });
  };

  // EFFECTS  //////////////////////////

  /**
   * LOCAL SETTINGS RETRIEVAL
   *
   * Effect that checks if localSettings state
   * is null and we are not fetching to
   * launch the loadLocalSettings method.
   */
  useEffect(() => {
    if (
      localSettingsState.localSettings === null &&
      !localSettingsState.isFetching
    ) {
      loadLocalSettings();
    }
  }, [
    loadLocalSettings,
    localSettingsState.isFetching,
    localSettingsState.localSettings
  ]);

  /**
   * Load Defaults
   *
   * Effect that checks if the different settings exists
   * and sets the default value in case it doesn't.
   */
  useEffect(() => {
    if (
      localSettingsState.localSettings &&
      !localSettingsState.isFetching &&
      !localSettingsState.isReady
    ) {
      let tmpLocalSettings = getLocalSettingsClone();

      // Tabbed results default.
      if (!tmpLocalSettings.hasOwnProperty("tabbedResults")) {
        tmpLocalSettings.tabbedResults = false;
      }
      // Show pylons default.
      if (!tmpLocalSettings.hasOwnProperty("showPylons")) {
        tmpLocalSettings.showPylons = true;
      }

      // Show labels default.
      if (!tmpLocalSettings.hasOwnProperty("showLabels")) {
        tmpLocalSettings.showLabels = false;
      }
      // Show Map Routing Width default.
      if (!tmpLocalSettings.hasOwnProperty("showMapRoutingWidth")) {
        tmpLocalSettings.showMapRoutingWidth = true;
      }

      // Last Scenario by project default (empty)
      if (
        !localSettingsState.localSettings.hasOwnProperty(
          "lastScenarioByProject"
        )
      ) {
        tmpLocalSettings.lastScenarioByProject = {};
      }

      // Export Scenario Results Dialog
      if (!tmpLocalSettings.hasOwnProperty("exportScenarioResultsDialog")) {
        tmpLocalSettings.exportScenarioResultsDialog = {
          includeItems: [],
          pathFormats: [],
          coordinateReferenceSystem: []
        };
      }

      localSettingsDispatch({
        type: "LOCAL_SETTINGS_SET",
        payload: { localSettings: tmpLocalSettings }
      });

      localStorage.setItem(
        LOCAL_SETTINGS_KEY_NAME,
        JSON.stringify(tmpLocalSettings)
      );
      // Mark Ready
      localSettingsDispatch({ type: "SET_READY" });
    }
  }, [
    getLocalSettingsClone,
    localSettingsDispatch,
    localSettingsState.isFetching,
    localSettingsState.isReady,
    localSettingsState.localSettings
  ]);

  return {
    localSettingsState,
    uploadLocalSettings,
    toggleTabbedResults,
    toggleShowPylons,
    toggleShowLabels,
    toggleShowMapRoutingWidth,
    setLastScenario,
    deleteLocalProjectAndScenario,
    setActiveBaseMap,
    setLocalVisibility,
    setLocalVisibilityAll,
    setExportScenarioResults,
    // purgeLocalVisibility,
    setLocalOpacity,
    setLocalColor,
    setScenarioSelectorHeight
  };
};
export { LocalSettingsProvider, useLocalSettingsContext };
