import React, { useEffect, useState } from "react";

// Material UI
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

// i18n
import { useTranslation } from "react-i18next";

// Assets
import CookieIcon from "assets/icons/CookieIcon";
import { CookieState } from "assets/global";

// Context
import { useCookies } from "context/CookiesContext";

/**
 * CookieConsentBanner component displays a small banner for cookie consent.
 * The banner is shown based on the user's cookie consent choice.
 *
 * @returns {React.ReactNode|null} The CookieConsentBanner component or null if not visible.
 */
function CookieConsentBanner() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [visible, setVisible] = useState(false);

  const {
    cookieConsentChoice,
    acceptCookieConsent,
    isOpenCookieSettingsDialog,
    openCookieSettingsDialog
  } = useCookies();

  useEffect(() => {
    setVisible(
      cookieConsentChoice === CookieState.UNKNOWN &&
        isOpenCookieSettingsDialog === false
    );
  }, [cookieConsentChoice, isOpenCookieSettingsDialog]);

  if (!visible) return null;

  return (
    <div
      style={{
        width: "100%",
        background: "rgba(61, 65, 66, 0.85)",
        zIndex: 1301,
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        bottom: 0,
        position: "fixed"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: "auto",
          marginRight: "auto",
          width: "100%",
          maxWidth: 1300,
          gap: theme.spacing(5)
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: theme.spacing(3)
          }}
        >
          <CookieIcon
            alt="cookie-img"
            style={{
              opacity: 0.7,
              color: "white",
              fontSize: "50px"
            }}
          />
          {/* Cookie Consent text */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 4
            }}
          >
            <Typography variant="h5" style={{ color: "#00BEF3" }}>
              {t("cookies.CookieConsentTitle")}
            </Typography>
            <Typography variant="body1" style={{ color: "#ffffff" }}>
              {t("cookies.CookieConsentDescription")}
            </Typography>
          </div>
        </div>
        {/* Buttons */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: theme.spacing(2),
            minWidth: 300
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={acceptCookieConsent}
          >
            {t("cookies.CookiesConsentAccept")}
          </Button>
          <Button variant="text" onClick={openCookieSettingsDialog}>
            {t("cookies.CookiesConsentManage")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CookieConsentBanner;
