/**
 * Regular expression pattern for matching a hexadecimal color code.
 */
const HEX_COLOR_MATCHER =
  /^#(?<r>\p{AHex}{2})(?<g>\p{AHex}{2})(?<b>\p{AHex}{2})$/u;

/**
 * Converts a color string to its corresponding hexadecimal representation. It works in almost
 * every browser, even in really old ones.
 * It converts any CSS color string to a hexadecimal representation, from any format (rgb, rgba, hsl, hsla, hex, named CSS color...) to a hexadecimal string.
 * If the conversion fails, the fallback color will be returned.
 * Modified from https://stackoverflow.com/a/24366628
 *
 * @param {string} colorStr - The color string to convert.
 * @param {string} [fallbackColor="#000000"] - The fallback color to use if the conversion fails.
 * @returns {string} The hexadecimal representation of the color string, or the fallback color.
 */
export function getHexColorFromCSSColor(colorStr, fallbackColor = "#000000") {
  const a = document.createElement("div");
  a.style.color = colorStr;

  const colors = window
    .getComputedStyle(document.body.appendChild(a))
    .color.match(/\d+/g)
    .map(function (a) {
      return parseInt(a, 10);
    });

  document.body.removeChild(a);

  return colors.length >= 3
    ? "#" +
        ((1 << 24) + (colors[0] << 16) + (colors[1] << 8) + colors[2])
          .toString(16)
          .substr(1)
    : fallbackColor || "#000000";
}

/**
 * Converts a hexadecimal color code to an array of RGB values.
 *
 * @param {string} hex - The hexadecimal color code (6 digits).
 * @returns {number[]} An array of RGB values.
 * @throws {Error} If the input is not a valid 6-digit hex color.
 */
function hexToSrgb(hex) {
  const match = hex.match(HEX_COLOR_MATCHER);
  if (!match) throw new Error("Not a valid 6-digit hex color");
  const { r, g, b } = match.groups;

  return [r, g, b].map(x => parseInt(x, 16) / 255);
}

/**
 * Calculates the relative luminance of a given sRGB color.
 *
 * @param {number[]} srgb - An array representing the sRGB color values [R, G, B].
 * @returns {number} The relative luminance value.
 */
function relativeLuminance(srgb) {
  const [R, G, B] = srgb.map(i =>
    i <= 0.04045 ? i / 12.92 : ((i + 0.055) / 1.055) ** 2.4
  );
  return 0.2126 * R + 0.7152 * G + 0.0722 * B;
}

/**
 * Calculates the text color based on the background color.
 *
 * In the future, instead of this, we could just use CSS: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value/color-contrast#browser_compatibility
 *
 * @param {string} cssColor - The background color in any valid CSS color format.
 * @param {string} [lightColor="#000000"] - The default light color.
 * @param {string} [darkColor="#ffffff"] - The default dark color.
 * @returns {string} - The calculated text color.
 */
export function getTextColorBasedOnBackground(
  cssColor,
  lightColor = "#000000",
  darkColor = "#ffffff"
) {
  const hexColorFromCSSColor = getHexColorFromCSSColor(cssColor);

  return relativeLuminance(hexToSrgb(hexColorFromCSSColor)) > 0.179
    ? lightColor ?? "#000000"
    : darkColor ?? "#ffffff";
}

/**
 * Returns the extracted [r, g, b, a] values from a string
 * like "rgba(0, 5, 255, 0.8)". If no alpha is specified, return
 * undefined for it.
 *
 * @param {string} str Color in string.
 *
 * @return {array} Array with rgba values.
 */
export function rgbaFromString(str) {
  const m = str.match(
    /^rgba?\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(?:,\s*(\d+(?:\.\d+)?)\s*)?\)$/i
  );
  if (m) {
    return [
      parseInt(m[1]),
      parseInt(m[2]),
      parseInt(m[3]),
      m[4] === undefined ? 255 : parseFloat(m[4])
    ];
  } else {
    throw new Error(
      "Color " + str + " did not match pattern rgb[a](r, g, b[, a])."
    );
  }
}

/**
 * Return the extracted a string "rgba(r, g, b, a)",
 * from values in input array.
 *
 * @param {array} rgba Color in rgba array.
 *
 * @return {string} String with rgba values.
 */
export function stringFromRgba(rgba) {
  if (rgba && rgba.length == 4) {
    return (
      "rgba(" + rgba[0] + "," + rgba[1] + "," + rgba[2] + "," + rgba[3] + ")"
    );
  } else {
    throw new Error(
      "Color " + rgba + " did not match pattern rgba(r, g, b, a)."
    );
  }
}

/**
 * Return a string "rgba(r, g, b, a)" containing RGB values
 * from the input string multiplied by a modulating factor
 * typically smaller than 1.0.
 *
 * @param {string} rgba Color in string.
 * @param {number} factor Modulating factor.
 *
 * @return {string} String with rgba values.
 *
 */
export function modulateRgbaString(rgba, factor) {
  let colorComponents = rgbaFromString(rgba);
  colorComponents[0] *= factor;
  colorComponents[1] *= factor;
  colorComponents[2] *= factor;
  return stringFromRgba(colorComponents);
}

/**
 * Given an HEX array it returns a rgba array with [R,G,B,A] values
 * between 0 and 255.
 *
 * @param {array} hex
 *
 * @returns {array} RGBA Color array.
 */
export function hexToRgba(hex) {
  var c;
  if (hex.includes("rgb")) {
    c = hex.substr(5).split(")")[0].split(",");
    c = c.map((item, index) => {
      if (index === 3) {
        item = parseInt(parseFloat(item) * 255);
      } else {
        item = parseInt(item);
      }
      return item;
    });
    return c;
  }
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return [(c >> 16) & 255, (c >> 8) & 255, c & 255, 255];
  }
  throw new Error("Bad Hex");
}

/**
 * Converts RGBA to HEX.
 * @param {string} rgba Color in rgba.
 *
 * @returns {string} Hex color.
 */
export function componentToHex(rgba) {
  let hex = rgba.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

/**
 * Hexadecimal to rgb color.
 *
 * @param {string} hex HEX Color
 *
 * @returns {object} RGB Color.
 */
export function hexToRgb(hex) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
}

/**
 * Calculate complementary color.
 *
 * @param {number} r
 * @param {number} g
 * @param {number} b
 *
 * @returns {string}
 */
export function rgbComplimentary(r, g, b) {
  let hex = "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  let rgb =
    "rgb(" +
    (hex = hex.replace("#", ""))
      .match(new RegExp("(.{" + hex.length / 3 + "})", "g"))
      .map(function (l) {
        return parseInt(hex.length % 2 ? l + l : l, 16);
      })
      .join(",") +
    ")";

  // Get array of RGB values
  rgb = rgb.replace(/[^\d,]/g, "").split(",");

  let newR = rgb[0] / 255.0,
    newG = rgb[1] / 255.0,
    newB = rgb[2] / 255.0;

  let max = Math.max(newR, newG, newB);
  let min = Math.min(newR, newG, newB);
  let h,
    s,
    l = (max + min) / 2.0;

  if (max === min) {
    h = s = 0; //achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2.0 - max - min) : d / (max + min);

    if (max === newR && newG >= newB) {
      h = (1.0472 * (newG - newB)) / d;
    } else if (max === newR && newG < newB) {
      h = (1.0472 * (newG - newB)) / d + 6.2832;
    } else if (max === newG) {
      h = (1.0472 * (newB - newR)) / d + 2.0944;
    } else if (max === newB) {
      h = (1.0472 * (newR - newG)) / d + 4.1888;
    }
  }

  h = (h / 6.2832) * 360.0 + 0;

  // Shift hue to opposite side of wheel and convert to [0-1] value
  h += 180;
  if (h > 360) {
    h -= 360;
  }
  h /= 360;

  // Convert h s and l values into newR newG and newB values
  // Adapted from answer by Mohsen http://stackoverflow.com/a/9493060/4939630
  if (s === 0) {
    newR = newG = newB = l; // achromatic
  } else {
    var hue2rgb = function hue2rgb(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    let p = 2 * l - q;

    newR = hue2rgb(p, q, h + 1 / 3);
    newG = hue2rgb(p, q, h);
    newB = hue2rgb(p, q, h - 1 / 3);
  }

  newR = Math.round(newR * 255);
  newG = Math.round(newG * 255);
  newB = Math.round(newB * 255);

  // Convert newR newB and newG values to hex
  rgb = newB | (newG << 8) | (newR << 16);
  return hexToRgb("#" + (0x1000000 | rgb).toString(16).substring(1));
}
