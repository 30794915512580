import React from "react";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from "prop-types";
import ExitButton from "components/_universal/ExitButton";

/**
 * Custom component to be used for all dialogs of standard size "large".
 * The component acts as a wrapper of the material-ui Dialog component,
 * ensuring that all "large" dialogs have consistent styling across the app.
 *
 * To use it, simply render the component including all dialog contents nested
 * inside as children components. Any additional props on this component
 * will be passed on to the wrapped Dialog component.
 *
 * There are also custom components in this same folder for typical material-ui
 * dialog subcomponents: CustomDialogTitle, CustomDialogContent, CustomDialogContentText
 * and CustomDialogActions. Simply nest them inside this LargeCustomDialog component,
 * together with any additional content you want to include inside the dialog.
 */
function LargeCustomDialog(props) {
  const { children, onClose, ...other } = props;
  return (
    <Dialog onClose={onClose} {...other} maxWidth="md" fullWidth>
      {!props?.hideDefaultCloseButton && <ExitButton onClose={onClose} />}
      {children}
    </Dialog>
  );
}

LargeCustomDialog.propTypes = {
  /** boolean value */
  open: PropTypes.bool,
  /** function prop */
  onClose: PropTypes.func,
  /** optional boolean to hide the default close button and use a custom one */
  hideDefaultCloseButton: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
  PaperProps: PropTypes.object,
  onEnter: PropTypes.func,
  onKeyDown: PropTypes.func,
  classes: PropTypes.object
};

export default LargeCustomDialog;
