import React, { useContext, Suspense, lazy, useEffect } from "react";

// Third-party
import { Route } from "react-router-dom";
import "@kxtells/ol-geocoder/dist/ol-geocoder.min.css";

// Material UI
import CssBaseline from "@material-ui/core/CssBaseline";

// Context
import { AuthContext } from "AuthProvider";
import ProcessProvider from "ProcessProvider";
import ModalProvider from "ModalProvider";
import PaletteProvider from "context/PaletteContext";
import IdentifyProvider from "context/IdentifyContext";
import UiProvider from "context/UiContext";
import AllResults from "context/AllResults";
import GoogleAnalyticsProvider from "context/GoogleAnalyticsContext/GoogleAnalyticsContext";

// Components
import CookieConsentBanner from "features/Cookies/CookieConsentBanner/CookieConsentBanner";
import LiveChat from "components/LiveChat/LiveChat";

// Constants
const Dashboard2D = lazy(() => import("components/Dashboard2D/Dashboard2D"));
const Dashboard3D = lazy(() => import("components/Dashboard3D"));
const Login = lazy(() => import("components/Authorization/Login"));
const TwoFactorVerification = lazy(
  () => import("components/Authorization/TwoFactorVerification")
);

// Utils
import getenvvar from "utils/Env";

// Other
import "./App.css";
const LiveChat_License = getenvvar("REACT_APP_LIVECHAT_LICENSE");

/**
 * Main application entry point.
 *
 * @returns React.ReactElement
 */
function App() {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    window.CESIUM_BASE_URL = "./Cesium";
  }, []);

  function onLiveChatStateChanged(e) {
    authContext.setChatState(e.state);
  }

  function onLiveChatLoaded() {
    authContext.setChatLoaded(true);
  }

  if (!authContext.loading) {
    if (authContext.isAuthenticathed) {
      return (
        <Suspense fallback={<></>}>
          <GoogleAnalyticsProvider>
            <Route path="/">
              <UiProvider>
                <LiveChat
                  license={LiveChat_License}
                  onChatLoaded={onLiveChatLoaded}
                  onChatStateChanged={onLiveChatStateChanged}
                />

                <IdentifyProvider>
                  <PaletteProvider>
                    <ModalProvider>
                      <ProcessProvider>
                        <AllResults>
                          <CookieConsentBanner />

                          <CssBaseline />

                          <div className="content pa0 bg-black h-100 w-100">
                            <Route exact path="/" component={Dashboard2D} />
                            <Route exact path="/3d" component={Dashboard3D} />
                            <div
                              id="pdf-generation-map"
                              style={{
                                position: "absolute",
                                top: "0px",
                                left: "0px",
                                right: "0px",
                                bottom: "0px",
                                width: "100vw",
                                height: "50vw",
                                zIndex: -1
                              }}
                            />
                          </div>
                        </AllResults>
                      </ProcessProvider>
                    </ModalProvider>
                  </PaletteProvider>
                </IdentifyProvider>
              </UiProvider>
            </Route>
          </GoogleAnalyticsProvider>
        </Suspense>
      );
    } else {
      return (
        <div className="content pa0 h-100 w-100">
          <Suspense fallback={<></>}>
            <Route>
              <Route exact path="/" component={props => <Login {...props} />} />

              <Route
                exact
                path="/verify"
                component={props => <TwoFactorVerification {...props} />}
              />
            </Route>
          </Suspense>
        </div>
      );
    }
  } else {
    return <div></div>;
  }
}

export default App;
