// Cesium
import {
  UrlTemplateImageryProvider,
  BingMapsImageryProvider,
  BingMapsStyle,
  WebMapTileServiceImageryProvider,
  WebMercatorTilingScheme,
  Credit,
  NearFarScalar,
  Cartesian3,
  HeadingPitchRange,
  Math,
  sampleTerrainMostDetailed,
  Cartographic,
  HeightReference,
  Color,
  LabelStyle,
  VerticalOrigin,
  Cartesian2,
  Entity,
  Transforms,
  Matrix4,
  when,
  HeadingPitchRoll
} from "cesium/Build/Cesium/Cesium";

// Third-party
import i18n from "i18next";
import { bearing, degreesToRadians, point } from "@turf/turf";

// Utils
import { removeCesiumImageryLayerByName } from "utils/Cesium/CesiumLayerUtils";
import {
  cartographicFromCartesian,
  cartographicFromDegrees,
  getCesiumElevatonPoints
} from "utils/Cesium/CesiumCoordinatesUtils";

// Other
import {
  CAMERA_LOOK_DEFAULT_FACTOR,
  CAMERA_MOVEMENT_DEFAULT_FACTOR,
  EPSG_3857,
  HUMAN_EYE_HEIGHT
} from "assets/global";

/**
 * Get imagery provider of the given background.
 *
 * @return  {object} The imagery provider of the background.
 */
export function get3DProvider(background) {
  let imageryProvider;
  switch (background.provider) {
    case "Google maps":
      imageryProvider = new UrlTemplateImageryProvider({
        url: background.url,
        format: "image/jpeg"
      });
      break;
    case "Bing maps": {
      let extra = background.extra_options;
      imageryProvider = new BingMapsImageryProvider({
        url: background.url,
        key: background.apikey,
        mapStyle: Object.hasOwn(extra, "imagerySet")
          ? extra.imagerySet
          : BingMapsStyle.AERIAL
      });
      break;
    }
    case "empty":
      imageryProvider = null;
      break;
    case "Local":
    case "XYZ":
    case "URL":
      imageryProvider = new UrlTemplateImageryProvider({
        url: background.url,
        format: "image/jpeg"
      });
      break;
    case "Local reverse":
      imageryProvider = new UrlTemplateImageryProvider({
        url: background.url + "/{z}/{x}/{reverseY}.png",
        format: "image/jpeg"
      });
      break;

    case "WMTS": {
      {
        if (!background.extra_options.layer) {
          console.log("layer parameter is missing in extra options");
          break;
        }
        if (!background.extra_options.style) {
          console.log(
            "style parameter is missing in extra options. In Geonode, it may be the layer name"
          );
          break;
        }

        let tileMatrixPrefix = "";
        let matrixSet = EPSG_3857;

        if (background.extra_options.tileMatrixPrefix)
          tileMatrixPrefix = background.extra_options.tileMatrixPrefix;

        if (background.extra_options.matrixSet)
          matrixSet = background.extra_options.matrixSet;

        let maximumLevel = 18;
        let matrixIds = new Array(maximumLevel + 1);
        let z;
        for (z = 0; z <= maximumLevel; ++z) {
          matrixIds[z] = tileMatrixPrefix + z;
        }
        imageryProvider = new WebMapTileServiceImageryProvider({
          url: background.url,
          layer: background.extra_options.layer,
          style: background.extra_options.style,
          format: "image/png",
          tileMatrixSetID: matrixSet,
          tileMatrixLabels: matrixIds,
          tilingScheme: new WebMercatorTilingScheme(),
          maximumLevel: maximumLevel,
          credit: new Credit("")
        });
        break;
      }
    }

    default:
      break;
  }
  return imageryProvider;
}

/**
 * Change the Cesium BaseMap to the provided baseMap Definition
 * BaseMap definitions contain a list of layers to load as a
 * BaseMap.
 *
 * @param {Cesium.viewer} viewer Cesium viewer to modify.
 * @param {object} basemapDef A definiton of a Pathfinder Basemap
 *
 */
export function changeCesiumBasemap(viewer, basemapDef) {
  removeCesiumImageryLayerByName(viewer, "BaseMap");

  //Inverse loop since we want those imagery layers at the bottom
  //ordered as they come
  const layers = basemapDef?.layers ?? [];
  for (let i = layers.length - 1; i >= 0; i--) {
    let imageryProvider = get3DProvider(layers[i]);
    // Remove the old base maps
    let iLayers = viewer.scene.globe.imageryLayers;
    // Add new basemap layer
    if (imageryProvider) {
      let imgLayer = iLayers.addImageryProvider(imageryProvider);
      imgLayer.name = "BaseMap";
      iLayers.lowerToBottom(imgLayer);
      viewer.scene.requestRender();
    }
  }
}

/**
 * Get Cesium color from CSS Hex code with a given opacity.
 *
 * @param {string} colorName The Hex code of the color.
 * @param {number} alpha The opacity level.
 */
export function getColorByString(colorName, alpha) {
  if (!colorName) return false;
  const color = Color.fromCssColorString(colorName);
  if (color?.alpha && !alpha) {
    return color;
  } else {
    return Color.fromAlpha(color, parseFloat(alpha));
  }
}

/**
 * Get cesium color by Cesium name with a given opacity.
 *
 * @param {string} colorName The Hex code of the color.
 * @param {number} alpha The opacity level.
 */
export function getColorByName(colorName, alpha) {
  let color = Color[colorName.toUpperCase()];
  return Color.fromAlpha(color, parseFloat(alpha));
}

/**
 * Change Cesium globe background color.
 *
 * @param {Cesium.viewer} viewer Cesium viewer to modify.
 * @param {string} color Color (Hex).
 */
export function changeCesiumBackgroundColor(viewer, color = "#FFFFFF") {
  viewer.scene.globe.baseColor = getColorByString(color);
}

/**
 * Change Cesium globe transparency.
 *
 * @param {Cesium.viewer} viewer Cesium viewer to modify.
 * @param {boolean} enabled. Enable or disable the terrain transparency
 */
export function setCesiumBackgroundTransparency(viewer, enabled = true) {
  let scene = viewer.scene;
  let globe = scene.globe;

  globe.translucency.frontFaceAlphaByDistance = new NearFarScalar(
    1500,
    0.5,
    5000,
    1.0
  );
  globe.undergroundColor = getColorByString("#ece2c6");
  scene.screenSpaceCameraController.enableCollisionDetection = !enabled;
  globe.translucency.enabled = enabled;
}

/**
 * Method that flies to the given coordinates in the globe with a cenital view.
 * Height is automaticaly set to the terrain height + 600m zooming with
 * altitudeToTerrain, otherwise the height is just set to 600
 *
 * @param {object} viewer Cesium viewer instance
 * @param {number} lon longitude to zoom to
 * @param {number} lat latitude to zoom to
 * @param {boolean} altitudeToTerrain automatically adjust the zoom point Z
 *                                    position to the terrain altitude
 */
export async function cesiumZoomToCoordinates(
  viewer,
  lon,
  lat,
  altitudeToTerrain = false
) {
  if (altitudeToTerrain) {
    getCesiumElevatonPoints([cartographicFromDegrees(lon, lat)])
      .then(point => {
        //Fly To specific coordinate with an extra 1km height
        viewer.camera.flyTo({
          destination: Cartesian3.fromDegrees(lon, lat, point[0].height + 600)
        });
      })
      .catch(error => {
        console.error(error);
      });
  } else {
    viewer.camera.flyTo({
      destination: Cartesian3.fromDegrees(lon, lat, 1000),
      offset: new HeadingPitchRange(
        Math.toRadians(0),
        Math.toRadians(-90.0),
        1000
      )
    });
  }
}

/**
 * Get tooltip div element.
 */
export function getCesiumTooltip() {
  let tooltip = document.getElementById("cesium-tooltip");
  tooltip.style.display = "none";
  tooltip.innerHTML = "";
  return tooltip;
}

/**
 * Creates Geocoder Marker billboard.
 *
 * @param {number} lon Longitude of the geocoder marker position.
 * @param {number} lat Latitude  of the geocoder marker position.
 * @param {string} color Color of the geocoder marker.
 */
export function createGeocoderIcon(lon, lat, color) {
  return new Entity({
    position: Cartesian3.fromRadians(lon, lat),
    name: "GeocoderPin",
    billboard: {
      image: "./iconW.png",
      color: color,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      verticalOrigin: VerticalOrigin.BOTTOM,
      scaleByDistance: new NearFarScalar(1.5e1, 1.0, 1.5e7, 0.5),
      scale: 0.1,
      heightReference: HeightReference.CLAMP_TO_GROUND
    }
  });
}

/**
 * Change alpha value entity color.
 *
 * @param {object} datasource Datasource where the enity belongs.
 * @param {number} id The id of the entity.
 * @param {number} alpha The new alpha value.
 */
export function changeFlagAlphaById(datasource, id, alpha = 1) {
  let entity = datasource.entities.getById(id);
  if (entity) {
    let color = entity.billboard.color.getValue().withAlpha(alpha);
    entity.billboard.color.setValue(color);
    if (alpha === 0) {
      entity.label.show = false;
    } else {
      entity.label.show = true;
    }
  }
}

/**
 *  Create Project Start/End Markers.
 *
 * @param {number} id
 * @param {array} coordinates
 * @param {string} color
 * @param {string} text
 *
 * @returns {Cesium.Entity} The Cesium entity to use as marker.
 */
export function createFlagEntity(id, coordinates, color, text) {
  return new Entity({
    position: Cartesian3.fromDegrees(coordinates[0], coordinates[1]),
    billboard: {
      image: "./iconW.png",
      color: color,
      verticalOrigin: VerticalOrigin.BOTTOM,
      scaleByDistance: new NearFarScalar(1.5e1, 1.0, 1.5e7, 0.5),
      scale: 0.1,
      heightReference: HeightReference.CLAMP_TO_GROUND
    },
    label: {
      text: text,
      font: "bold 18px Calibri",
      fillColor: getColorByString("#333333"),
      outlineColor: getColorByString("#ffffff"),
      outlineWidth: 4,
      style: LabelStyle.FILL_AND_OUTLINE,
      heightReference: HeightReference.CLAMP_TO_GROUND,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      pixelOffset: new Cartesian2(0, -2)
    },
    id: id
  });
}

/**
 * Renders start/end points of the project.
 *
 * @param {object} projectVector The source where the entity belongs.
 * @param {array} coordinates The coordinates for the marker.
 * @param {string} id The id of the marker.
 * @param {boolean} isStart If true, it's the start marker, otherwise is the end marker.
 *
 * @returns
 */
export function drawProjectPoints(
  projectVector,
  coordinates,
  id,
  isStart = true
) {
  if (!coordinates) return;

  let flagEntity = projectVector.entities.getById(id);

  // Update the current point if exist
  if (flagEntity) {
    flagEntity.position = Cartesian3.fromDegrees(
      coordinates[0],
      coordinates[1]
    );
  }

  // Create new flag if not exist
  else {
    let name = isStart ? "common.start" : "common.end";
    let color = isStart
      ? getColorByString("#0fff7b")
      : getColorByString("#0fa7ff");
    projectVector.entities.add(
      createFlagEntity(id, coordinates, color, i18n.t(name).toUpperCase())
    );
  }
}

/**
 * Create Intermediate Entity.
 *
 * @param {array} point Point coordinates.
 * @param {string} text Caption for the point.
 *
 * Is not posible add billboard and point https://github.com/CesiumGS/cesium/issues/7357
 */
export function createIntermediateEntity(point, text) {
  return new Entity({
    position: point,
    point: {
      heightReference: HeightReference.CLAMP_TO_GROUND,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      color: getColorByString("#00BEF3", 0.2),
      pixelSize: 10,
      outlineColor: getColorByString("#00BEF3"),
      outlineWidth: 2
    },
    label: {
      text: text,
      font: "bold 16px sans-serif",
      fillColor: getColorByString("#333333"),
      outlineColor: getColorByString("#ffffff"),
      outlineWidth: 4,
      showBackground: true,
      backgroundColor: getColorByString("#F92E05"),
      style: LabelStyle.FILL_AND_OUTLINE,
      heightReference: HeightReference.CLAMP_TO_GROUND,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      pixelOffset: new Cartesian2(0, -18)
    }
  });
}

/**
 * Remove Cesium Entity by Id.
 *
 * @param {object} viewer Cesium viewer instance.
 * @param {string} id Id of the entity to remove.
 */
export function removeMapEntityById(viewer, id) {
  let entityToRemove = viewer.entities.getById(id);
  if (entityToRemove) {
    viewer.entities.remove(entityToRemove);
  }
  viewer.scene.requestRender();
}

/**
 * Draw Street View Point.
 *
 * @param {object} viewer Cesium viewer instance.
 * @param {array} coordinates Coordinates where to place the marker.
 * @param {string} id Id of the street view point.
 */
export function drawSVPoint(viewer, coordinates, id) {
  let heading = 0;
  let pitch = 0;
  let roll = 0;
  let currentPosition = Cartesian3.fromDegrees(coordinates[0], coordinates[1]);
  var hpr = new HeadingPitchRoll(heading, pitch, roll);

  var orientation = Transforms.headingPitchRollQuaternion(currentPosition, hpr);

  viewer.entities.add({
    id: id,
    position: Cartesian3.fromDegrees(coordinates[0], coordinates[1]),
    orientation: orientation,
    model: {
      uri: "./arrow.glb",
      color: Color.BLUE,
      allowPicking: false,
      show: true,
      scale: 30.0,
      heightReference: HeightReference.CLAMP_TO_GROUND,
      minimumPixelSize: 30,
      maximumScale: 200000
    }
  });
}

/**
 * Prevent go to underground.
 *
 * @param {object} viewer Cesium viewer instance.
 * @param {number} terrain_height
 */
export function unburrow(viewer, terrain_height) {
  let cp = viewer.scene.camera.position;
  let camera_height =
    viewer.scene.globe.ellipsoid.cartesianToCartographic(cp).height;

  //Determine terrain relative height (edist)
  let edist = camera_height - terrain_height;

  //Unburrow code
  if (edist < 5) {
    let GD_transform = Transforms.eastNorthUpToFixedFrame(
      cp,
      viewer.scene.globe.ellipsoid,
      new Matrix4()
    );
    let GD_ENU_U = new Cartesian3(
      GD_transform[8],
      GD_transform[9],
      GD_transform[10]
    );
    let temp = new Cartesian3();
    Cartesian3.multiplyByScalar(
      GD_ENU_U,
      terrain_height - camera_height + 100,
      temp
    );
    viewer.scene.requestRender();
    viewer.scene.camera.position.x += temp.x;
    viewer.scene.camera.position.y += temp.y;
    viewer.scene.camera.position.z += temp.z;
    viewer.scene.requestRender();
  }
}

/**
 * Function to fix height pedestrian position using the terrain height.
 * Prevent go to underground
 *
 * @param {object} viewer Cesium viewer instance.
 */
export function fixHeightPedestrianPosition(viewer) {
  let camera = viewer.scene.camera;

  let cartographic = cartographicFromCartesian(camera.position);

  let height = viewer.scene.globe.getHeight(cartographic);

  cartographic.height = height + HUMAN_EYE_HEIGHT;

  let promise = sampleTerrainMostDetailed(viewer.terrainProvider, cartographic);
  when(promise, res => {
    camera.position = Cartographic.toCartesian(res);
    unburrow(viewer, res.height);
  });
}

/**
 * This function will handel viewer events to control Cesium viewer view.
 *
 * @param {object} event The fired event.
 * @param {object} viewer Cesium viewer instance.
 * @param {string} cameraMode The type of camera.
 *
 */
export function handleUserKeyPress(event, viewer, cameraMode) {
  const { code, target } = event;
  // Prevent move viewer when the user is inside INPUT elements
  if (target instanceof HTMLInputElement || !viewer) {
    return;
  }

  event.preventDefault();
  let keyCode = code;
  let scene = viewer.scene;
  let camera = scene.camera;
  let ellipsoid = scene.globe.ellipsoid;
  let isPestrian = cameraMode === "pedestrian";

  let cameraHeight = ellipsoid.cartesianToCartographic(camera.position).height;

  // Default move Rate in meters
  let moveRate = cameraHeight / CAMERA_MOVEMENT_DEFAULT_FACTOR;

  if (cameraMode === "pedestrian") {
    moveRate = 100;
  } else if (cameraHeight < 100 && cameraHeight > 2) {
    moveRate = 20;
  } else if (cameraHeight < 2) {
    return;
  }

  switch (keyCode) {
    case "ArrowUp":
      // 'moveForward'
      camera.moveForward(moveRate);
      if (isPestrian) {
        fixHeightPedestrianPosition(viewer);
      }

      break;
    case "ArrowDown":
      // 'moveBackward';
      camera.moveBackward(moveRate);
      if (isPestrian) {
        fixHeightPedestrianPosition(viewer);
      }
      break;
    case "KeyW":
      // 'moveUp';
      camera.moveUp(moveRate);
      if (isPestrian) {
        fixHeightPedestrianPosition(viewer);
      }
      break;
    case "KeyS":
      // 'moveDown';
      camera.moveDown(moveRate);
      if (isPestrian) {
        fixHeightPedestrianPosition(viewer);
      }
      break;
    case "ArrowRight":
      // 'moveRight';
      if (!isPestrian) {
        camera.moveRight(moveRate);
      } else {
        camera.lookRight(CAMERA_LOOK_DEFAULT_FACTOR);
      }
      break;
    case "ArrowLeft":
      // 'moveLeft';
      if (!isPestrian) {
        camera.moveLeft(moveRate);
      } else {
        camera.lookLeft(CAMERA_LOOK_DEFAULT_FACTOR);
      }
      break;
    default:
      break;
  }

  scene.requestRender();
}

/**
 * Method to obtain the orientation of an objext in a given
 * `position` facing from point A to point B.
 *
 * @param {array} pointA Point from where we face to pointB.
 * @param {array} pointB Point where we face from pointA.
 * @param {object} position Position of the object to set the orientation.
 *
 * @returns {object} Quaternion (x, y, z, w) representing the orientation.
 */
export function getOrientation(pointA, pointB, position) {
  const from = point(pointA);
  const to = point(pointB);

  const pointsBearing = bearing(from, to);
  const heading = degreesToRadians(pointsBearing);
  const hpr = new HeadingPitchRoll(heading);

  // Ensure the position is a Cartesian3 object.
  // If it's not, convert it using Cartesian3.fromDegrees
  const cartPosition = Cartesian3.fromDegrees(
    position[0],
    position[1],
    position[2]
  );

  const orientation = Transforms.headingPitchRollQuaternion(cartPosition, hpr);

  return orientation;
}
