/**
 * Get a Cesium datasource by its name.
 *
 * @param {Cesium.viewer} viewer Cesium viewer.
 * @param {string} searchName Name of the datasource.
 */
export function getDatasourceLayerByName(viewer, searchName) {
  let data;
  viewer.dataSources.getByName(searchName).forEach(dataSource => {
    data = dataSource;
  });
  return data;
}

/**
 * Zoom to a Cesium datasource by name.
 *
 * @param {Cesium.viewer} viewer Cesium viewer.
 * @param {string} datasourceName Name of the datasource.
 */
export function zoomDatasourceByName(viewer, datasourceName) {
  let datasource = getDatasourceLayerByName(viewer, datasourceName);
  viewer.flyTo(datasource);
  viewer.scene.requestRender();
}

/**
 * Tooggle a Cesium datasource visibility by name.
 *
 * @param {Cesium.viewer} viewer Cesium viewer.
 * @param {string} datasourceName Name of the datasource.
 * @param {boolean} visible If true, will show the datasource.
 */
export function toggleDatasourceVisiblity(viewer, datasourceName, visible) {
  let dataSource = getDatasourceLayerByName(viewer, datasourceName);
  dataSource.show = visible;
  viewer.scene.requestRender();
}
